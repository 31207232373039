import { AccountRouteGuard } from './../../../account/auth/account-route-guard';
import { element } from 'protractor';
import { CableproXmlInputOpen, ProjectOutput, SubscriptionServiceProxy, UserLoginInfoDto } from './../../../shared/service-proxies/service-proxies';
import { Component, OnInit, AfterViewInit, Injector, Injectable, Inject, Optional, InjectionToken, Pipe, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { SettingServiceProxy, ProtectiveXmlInput, SettingInput, ManufacturerOutput, MCBOutput, BreakingOutput, DeviceOutput, SettingOutput, CustomereportFieldsInput, IFormFile, GetCurrentLoginInformationsOutput } from '@shared/service-proxies/service-proxies';
import { DrpClass } from './drpClass';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { fromEvent, Observable, Subject } from 'rxjs';
import { SettingService, ProductService } from '../../../services/project.service';
import * as moment from 'moment';
import { LoaderService } from '../cablepro/loader.service';
import { debounceTime, finalize } from 'rxjs/operators';
import 'chart.js';
import { CableproCalculationServiceProxy } from '@shared/service-proxies/service-proxy-calc';

import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import { MessageService } from 'abp-ng2-module';
import { isNaN } from 'lodash';
import { ImageUtils } from '@shared/helpers/ImageUtils';
import { NgForm } from '@angular/forms';
import { ConfirmationModalComponent } from '@app/shared/common/confirmation-modal/confirmation-modal.component';
import { CompanyAccountService } from '@shared/service-proxies/company-account.service';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { AppConsts } from '@shared/AppConsts';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { MomentHelper } from '@shared/helpers/MomentHelper';
import { Options } from '@angular-slider/ngx-slider';
export const API_BASE_URL2 = new InjectionToken<string>('API_BASE_URL2');
@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    animations: [appModuleAnimation()]
})
export class SettingsComponent extends AppComponentBase implements OnInit, AfterViewInit {
    // @ViewChild(BaseChartDirective) chart: BaseChartDirective;
    public settingInput: SettingInput = new SettingInput();
    public customFields: CustomereportFieldsInput[] = [];
    public settingOutput: SettingOutput = new SettingOutput();
    public copyUtilityObj: any = {
        CalculationUrl: '',
        UserName: '',
        ProjectName: '',
    }

    frmSubmitSampleCalc = false;
    SampleCalcLoader: boolean = false;
    public sampleCalcObj: any = {
        TargetUserType: '',
        UserNames: '',
        ProjectId: '',
        ProjectType: '',
        CableSizeStandard: ''
    }
    // public manufacturerOutput: ManufacturerOutput = new ManufacturerOutput();
    public manufacturerOutput: ManufacturerOutput[] = [];
    public filesOutput: ManufacturerOutput[] = [];
    public familyOutput: ManufacturerOutput[] = [];
    public selectedFamily: ManufacturerOutput = new ManufacturerOutput();
    public modelOutput: ManufacturerOutput[] = [];
    public deviceOutput: DeviceOutput = new DeviceOutput();
    public mcbOutput: MCBOutput[] = [];
    public protectiveXmlInput: ProtectiveXmlInput = new ProtectiveXmlInput();
    public loadProtectiveXmlInput: ProtectiveXmlInput = new ProtectiveXmlInput();
    public changes: any;
    public curveOutput: MCBOutput[] = [];
    public ratingPlugList: MCBOutput[] = [];
    public rp: string = "";

    public polesTableOutput: MCBOutput[] = [];
    public xmlData: string = "";
    public inputxmlData: string = "";
    public rpcaption: string = "Rating plug";
    public chartData: string = "";
    public chartArray: any;
    public operationalTableOutput: MCBOutput[] = [];
    public breakingOutput: BreakingOutput[] = [];
    public indextoDelete: number = -1;
    public drpClass: DrpClass[] = [];
    private baseUrl: string;
    public defaultManId: number = 104;
    public defaultFamilyId: string = "";
    public defaultModelId: number = 0;
    public defaultRatingId: string = "";
    public defaultCurveId: string = "";
    // public prevModel: number= 0;
    public gridsize: number = 3;
    public issetvalue: boolean = false;
    public prevRating: string = "";
    public multiplierEIR: number = 0;
    public multiplierEIO: number = 0;
    public multiplierIN: number = 0;
    public deviceType: string = "1";
    public prevTripUnit: string = "";
    public defaultLogoImagePath = '/assets/common/images/Nologo.png';
    public deleteVisible: Boolean = false;
    public btnuploadDisabled: boolean = false;
    public isInvalidFileType: boolean = false;
    public isAdminUser: boolean = true;
    public isInvalidExcelFileType: boolean = false;
    private cableSizeStandards: string[] = ["Australian Standard AS/NZS 3008.1.1:2017", "New Zealand Standard AS/NZS 3008.1.2:2017", "British Standard BS 7671:2018"];
    public standard: number;
    public cableSizeValue: string = '';
    public premiumUser!: boolean;
    public planLevel: string | number = '';
    public selectListFive: string = 'Curve Class';
    public showFilter: boolean = false;
    public IsGeneric: boolean = true;
    public FilterBreaking: number = 5;
    public IsFormvalid: boolean = true;
    public isHighlightRow: number = 0;
    public gmanufacturerId: number = 0;
    public gmodelId: number = 0;
    public gfamilyId: string = "";
    public hasPlanExpired: boolean = false;
    private isPlanAddedManually: boolean = false;
    public roleId;
    public user: UserLoginInfoDto;
    public value: number = 0.2;
    public options: Options = {
        showTicks: true,
        stepsArray: [
            { value: 1, legend: '0.1' },
            { value: 2, legend: '0.2' },
            { value: 3, legend: '0.3' }]
    };
    public model: string;
    public modelChanged = new Subject<DeviceOutput>();
    public searchResult$: Observable<DeviceOutput>;


    public slidertmir: string = '';
    public slidertmircalc: string = '';
    public slidertmiroptions: any;

    public slidertmim: string = '';
    public slidertmimcalc: string = '';
    public slidertmimoptions: any;

    public slidermim: string = '';
    public slidermimcalc: string = '';
    public slidermimoptions: any;



    public hideSubmit: boolean = false;




    public slidercurrenttolerance: string = '';
    public slidercurrenttolerancecalc: string = '';
    public slidercurrenttoleranceoptions: any;

    public slidertimetolerance: string = '';
    public slidertimetolerancecalc: string = '';
    public slidertimetoleranceoptions: any = '';

    public slidertimetobreak: string = '';
    public slidertimetobreakcalc: string = '';
    public slidertimetobreakoptions: any = '';

    public slidereio: string = '';
    public slidereiocalc: string = '';
    public slidereiooptions: any = '';

    public slidereir: string = '';
    public slidereircalc: string = '';
    public slidereiroptions: any = '';

    public slideridmtl: string = '';
    public slideridmtlcalc: string = '';
    public slideridmtloptions: any = '';


    public slideretr: string = '';
    public slideretrcalc: string = '';
    public slideretroptions: any = '';

    public slidereisd: string = '';
    public slidereisdcalc: string = '';
    public slidereisdoptions: any = '';

    public slideretsd1: any = '1';
    public slideretsdcalc: string = '';
    public slideretsdoptions: any = '';

    public slidereli: string = '';
    public sliderelicalc: string = '';
    public sliderelioptions: any = '';

    public ddlmodel: number;
    public ddlmodelChanged = new Subject<DeviceOutput>();
    public ddlsearchResult$: Observable<DeviceOutput>;
    // chart start
    public lineChartData: ChartDataSets[] = [
        { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
        { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' },
        { data: [180, 480, 770, 90, 1000, 270, 400], label: 'Series C', yAxisID: 'y-axis-1' }
    ];
    public lineChartLabels: any = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    public lineChartOptions: (ChartOptions & { annotation: any }) = {
        responsive: true,
        scales: {
            // We use this empty structure as a placeholder for dynamic theming.
            xAxes: [{}],
            yAxes: [
                {
                    id: 'y-axis-0',
                    position: 'left',
                },
                {
                    id: 'y-axis-1',
                    position: 'right',
                    gridLines: {
                        color: 'rgba(255,0,0,0.3)',
                    },
                    ticks: {
                        fontColor: 'red',
                    }
                }
            ]
        },
        annotation: {
            annotations: [
                {
                    type: 'line',
                    mode: 'vertical',
                    scaleID: 'x-axis-0',
                    value: 'March',
                    borderColor: 'orange',
                    borderWidth: 2,
                    label: {
                        enabled: true,
                        fontColor: 'orange',
                        content: 'LineAnno'
                    }
                },
            ],
        },
    };
    //   public lineChartColors: Color[] = [
    //     { // grey
    //       backgroundColor: 'rgba(148,159,177,0.2)',
    //       borderColor: 'rgba(148,159,177,1)',
    //       pointBackgroundColor: 'rgba(148,159,177,1)',
    //       pointBorderColor: '#fff',
    //       pointHoverBackgroundColor: '#fff',
    //       pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    //     },
    //     { // dark grey
    //       backgroundColor: 'rgba(77,83,96,0.2)',
    //       borderColor: 'rgba(77,83,96,1)',
    //       pointBackgroundColor: 'rgba(77,83,96,1)',
    //       pointBorderColor: '#fff',
    //       pointHoverBackgroundColor: '#fff',
    //       pointHoverBorderColor: 'rgba(77,83,96,1)'
    //     },
    //     { // red
    //       backgroundColor: 'rgba(255,0,0,0.3)',
    //       borderColor: 'red',
    //       pointBackgroundColor: 'rgba(148,159,177,1)',
    //       pointBorderColor: '#fff',
    //       pointHoverBackgroundColor: '#fff',
    //       pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    //     }
    //   ];
    public lineChartLegend = true;
    public lineChartType: ChartType = 'line';
    public lineChartPlugins = [pluginAnnotations];
    public CableSize = {};
    frmSubmit = false;
    copyCalcLoader: boolean = false;
    downloadInputXmlLoader: boolean = false;
    @ViewChild('confirmationModal')
    modalComponent!: ConfirmationModalComponent;
    // chart end
    constructor(injector: Injector, private _router: Router,
        private _settingService: SettingService,
        private _CableproCalc: CableproCalculationServiceProxy,
        private _projectService: ProductService,
        private http: HttpClient,
        private _appSessionService: AppSessionService,
        private _companyAccountService: CompanyAccountService,
        private _authService: AppAuthService,
        private _messageService: MessageService,
        private _renderer2: Renderer2,
        private _subscriptionService: SubscriptionServiceProxy,
        @Inject(DOCUMENT) private _document: Document, private loaderService: LoaderService, private elementRef: ElementRef, @Optional() @Inject(API_BASE_URL2) baseUrl?: string) {

        super(injector);
        this.drpClass = [

            { id: 0, unitText: 'Current' },
            { id: 1, unitText: 'Power (kW)' }

        ];
        this.baseUrl = baseUrl ? baseUrl : "";
        this.modelChanged
            .pipe(
                debounceTime(300))
            .subscribe(() => {
                // this.setFilterBreaking();

                this.GetModelGroupByManufacturerIdnew(this.gmanufacturerId, this.gfamilyId, this.FilterBreaking);
            })
        this.ddlmodelChanged
            .pipe(
                debounceTime(300))
            .subscribe(() => {
                this.GetRatingByManufacturerId(this.gmanufacturerId, this.gfamilyId, this.gmodelId, true);

            })
    }

    // chart start
    private generateNumber(i: number): number {
        return Math.floor((Math.random() * (i < 2 ? 100 : 1000)) + 1);
    }

    // events
    public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    }
    showlongtimeprot() {
        if ($("#chcklongtime").is(":checked")) {
            $("#divlongtimeprot").show();
        }
        else {
            $("#divlongtimeprot").hide();
        }

        this.updateElectronic("", 0);

    }
    showshorttimeprot() {
        if ($("#chckshorttime").is(":checked")) {
            $("#divshorttime").show();
        }
        else {
            $("#divshorttime").hide();
        }
        this.updateElectronic("", 0);
    }

    //      

    showinstantprot() {
        if ($("#chckInstant").is(":checked")) {
            $("#divinstant").show();
        }
        else {
            $("#divinstant").hide();
        }
        this.updateElectronic("", 0);

    }
    chnagei2tcurve() {

        this.updateElectronic("", 0);

    }
    public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    }

    //   public hideOne(): void {
    //     const isHidden = this.chart.isDatasetHidden(1);
    //     this.chart.hideDataset(1, !isHidden);
    //   }

    public pushOne(): void {
        this.lineChartData.forEach((x, i) => {
            const num = this.generateNumber(i);
            const data: number[] = x.data as number[];
            data.push(num);
        });
        this.lineChartLabels.push(`Label ${this.lineChartLabels.length}`);
    }

    //   public changeColor(): void {
    //     this.lineChartColors[2].borderColor = 'green';
    //     this.lineChartColors[2].backgroundColor = `rgba(0, 255, 0, 0.3)`;
    //   }

    public changeLabel(): void {
        this.lineChartLabels[2] = ['1st Line', '2nd Line'];
    }
    // chart end
    changed() {
        this.modelChanged.next(undefined);
    }
    ddlchanged() {
        this.ddlmodelChanged.next(undefined);
    }

    ngOnInit() {
        this.GetDefaultSetting();
        document.getElementById('defaultOpen').click();
        this.onTableRowClick();

        this.CableSize = {
            xml: ''
        };
        this.onNeutralCondSelect();
        this.onDrpUnitSelect();
        // this.GetManufacturer();
        this.GetUploadedFiles();
        //this.GetProtectiveData();
        this.btnuploadDisabled = false;

        this.settingInput.manId = 100;



        //  this.slidertmir = "0";
        this.slidertmiroptions = {
            floor: "0",
            ceil: "1", step: "1"
        }


        // this.slidertmim ="-";
        this.slidertmimoptions = {
            showTicks: true,
            stepsArray: [
                { value: "-" }

            ]
        };
        this.slidermim = "-";
        this.slidermimoptions = {
            showTicks: true,
            stepsArray: [
                { value: "-" }

            ]
        };




        this.slidercurrenttolerance = "-";
        this.slidercurrenttoleranceoptions = {
            showTicks: true,
            stepsArray: [
                { value: "-" }

            ]
        };

        this.slidertimetolerance = "-";
        this.slidertimetoleranceoptions = {
            showTicks: true,
            stepsArray: [
                { value: "-" }

            ]
        };
        this.slidereio = "-";
        this.slidereiooptions = {
            showTicks: true,
            stepsArray: [
                { value: "-" }

            ]
        };

        this.slidereir = "-";
        this.slidereiroptions = {
            showTicks: true,
            stepsArray: [
                { value: "-" }

            ]
        };
        this.slideridmtl = "-";
        this.slideridmtloptions = {
            showTicks: true,
            stepsArray: [
                { value: "-" }

            ]
        };


        this.slideretr = "-";
        this.slideretroptions = {
            showTicks: true,
            stepsArray: [
                { value: "-" }

            ]
        };

        this.slidereisd = "-";
        this.slidereisdoptions = {
            showTicks: true,
            stepsArray: [
                { value: "-" }

            ]
        };


        this.slideretsd1 = "1";
        this.slideretsdoptions = {
            showTicks: true,
            stepsArray: [
                { value: "1" }, { value: "2" }

            ]
        };


        this.slidereli = "-";
        this.sliderelioptions = {
            showTicks: true,
            stepsArray: [
                { value: "-" }

            ]
        };
        this.slidertimetobreakoptions = {
            showTicks: true,
            stepsArray: [
                { value: "-" }

            ]
        };



    }
    ngAfterViewInit() {
        if ($('.c-select').length) {
            let $singleSelect = $('.c-select'),
                $singleSelectHasSearch = $('.c-select.has-search'),
                $multipleSelect = $('.c-select.c-select--multiple'),
                $disabledSelect = $('.c-select.is-disabled');

            $singleSelect.select2({
                width: '100%',
                minimumResultsForSearch: Infinity // disable search
            });

            // multiple select
            $multipleSelect.select2({
                width: '100%',
                multiple: true
            });

            // disabled select
            $disabledSelect.select2({
                width: '100%',
                disabled: true
            });
        }
        // $(".aslider").unbind('valueChange');
        // $(document).on('valueChange', '.aslider', (e: any) => {
        //     alert();
        // });

        $(document).on('click', '#gotoplanspricing', (e: any) => {

            this.setPlansUrl();
        });
        $(document).off('change', '#ratingplug');

        $(document).on('change', '#ratingplug', (e: any) => {

            var a = $('#ddlManufacturer option:selected').val();
            var manufacturerId = parseInt(a.toString());
            var familyId = this.model.toString();
            var c = $('#ddlModel option:selected').val();
            var modelId = parseInt(c.toString());
            var d = $('#ddlrating option:selected').val();
            var ee = $('#ddlcurve option:selected').val();
            var curve = (ee.toString());
            //  alert($('#ratingplug option:selected').val().toString());
            var ltp = "";

            if ($('#ratingplug option:selected').val() == "OFF")
                ltp = "0";
            this.LoadControls(manufacturerId, familyId, modelId, d.toString(), curve, '', '', '', '', '', ''
                , $('#ratingplug option:selected').val().toString(), ltp, '', ''
                , '', ''
                , '', ''
                , '', ''
                , '', '');
            // this.updateElectronic('#ratingplug',$('#ratingplug option:selected').val());
        });
        $(document).off('change', '#ddlManufacturer');

        $(document).on('change', '#ddlManufacturer', (e: any) => {
            if (e.target.options[e.target.selectedIndex] !== undefined) {

                this.loadProtectiveXmlInput = new ProtectiveXmlInput();
                var a = $('#ddlManufacturer option:selected').val();
                var manufacturerId = parseInt(a.toString());

                this.GetFamilyGroupByManufacturerId(manufacturerId);
                if ($("#ddlManufacturer option:selected").text().trim() != "Generic") {
                    this.IsGeneric = true;
                    var name = $("#ddlManufacturer option:selected").text().trim() + "logo";
                    $('#divlogo img').attr('src', 'assets/cablepro/logo/' + name + '.svg');
                    // $('#divlogo img').css('border', '2px dashed #ddd');
                }
                else {
                    $('#divlogo img').attr('src', 'assets/cablepro/logo/a.png');
                    //$('#divlogo img').css('border', '');
                    this.IsGeneric = false;

                }


                // this.GetModelGroupByManufacturerId(manufacturerId);
            }

        });

        $(document).off('change', '#ddlFamily');

        $(document).on('change', '#ddlFamily', (e: any) => {

            if (e.target.options[e.target.selectedIndex] !== undefined) {
                this.loadProtectiveXmlInput = new ProtectiveXmlInput();
                var a = $('#ddlManufacturer option:selected').val();
                var manufacturerId = parseInt(a.toString());
                this.model = $('#ddlFamily option:selected').val().toString();
                var familyId = this.model.toString();
                if (familyId != null && familyId != "") {
                    var arr = familyId.split(' ')[1];
                    if (arr != null && arr != "" && (arr == "MCCB" || arr == "ACB")) {
                        this.selectListFive = "Trip unit";
                    }
                    else if (arr != null && arr != "" && arr == "Fuse") {
                        this.selectListFive = "Fuse type";
                    }
                    else {
                        this.selectListFive = "Curve class";
                    }
                }
                this.gfamilyId = familyId;
                this.gmanufacturerId = manufacturerId;
                this.changed();


            }

        });



        $(document).off('change', '#ddlModel');

        $(document).on('change', '#ddlModel', (e: any) => {
            if (e.target.options[e.target.selectedIndex] !== undefined) {
                this.loadProtectiveXmlInput = new ProtectiveXmlInput();
                var a = $('#ddlManufacturer option:selected').val();
                var manufacturerId = parseInt(a.toString());
                var familyId = this.model;

                // var c = $('#ddlModel option:selected').val();
                this.ddlmodel = parseInt($('#ddlModel option:selected').val().toString());
                var modelId = this.ddlmodel;
                this.gmodelId = modelId;
                this.gfamilyId = familyId;
                this.gmanufacturerId = manufacturerId;
                // this.g=modelId;
                // this.GetRatingByManufacturerId(manufacturerId,familyId,modelId,true);
                // this.GetCurveByManufacturerId(manufacturerId,familyId,modelId,true);
                //  this.GetBreakingByManufacturerId(manufacturerId,familyId,modelId);

                this.ddlchanged();

            }

        });


        $(document).off('change', '#ddlrating');

        $(document).on('change', '#ddlrating', (e: any) => {
            //  alert();
            if (e.target.options[e.target.selectedIndex] !== undefined) {
                this.loadProtectiveXmlInput = new ProtectiveXmlInput();
                var a = $('#ddlManufacturer option:selected').val();
                var manufacturerId = parseInt(a.toString());

                var familyId = this.model.toString();
                var c = $('#ddlModel option:selected').val();
                var modelId = parseInt(c.toString());
                var d = $('#ddlrating option:selected').val();
                var rating = d.toString();
                // var ee = $('#ddlcurve option:selected').val();
                //var  curve=(ee.toString());
                this.GetCurveByManufacturerId(manufacturerId, familyId, modelId, true, rating);

                //    this.GetPolesTableByManufacturerId(manufacturerId,familyId,modelId,rating,curve);
                //  this.GetOperationalTableByManufacturerId(manufacturerId,familyId,modelId,rating,curve);

            }

        });

        $(document).off('change', '#ddlcurve');

        $(document).on('change', '#ddlcurve', (e: any) => {
            if (e.target.options[e.target.selectedIndex] !== undefined) {
                this.loadProtectiveXmlInput = new ProtectiveXmlInput();
                var a = $('#ddlManufacturer option:selected').val();
                var manufacturerId = parseInt(a.toString());

                var familyId = this.model.toString();
                //   //var c = $('#ddlModel option:selected').val();
                //   var  modelId=this.ddlmodel;
                var c = $('#ddlModel option:selected').val();
                var modelId = parseInt(c.toString());
                var d = $('#ddlrating option:selected').val();
                var rating = parseFloat(d.toString());
                var ee = $('#ddlcurve option:selected').val();
                var curve = (ee.toString());
                this.GetBreakingByManufacturerId(manufacturerId, familyId, modelId, d.toString(), curve);
                this.GetOperationalTableByManufacturerId(manufacturerId, familyId, modelId, rating, curve);

            }

        });
    }
    formatLabel(value: number) {
        if (value >= 1000) {
            return Math.round(value / 1000) + 'k';
        }

        return value;
    }
    getChart(data: any) {
        // var s = document.createElement("script");
        // s.type = "text/javascript";
        // s.src = "https://canvasjs.com/assets/script/canvasjs.min.js";
        // this.elementRef.nativeElement.appendChild(s);
        var a = "";
        var b = "";

        var t1x = [];
        var t1y = [];

        var t2x = [];
        var t2y = [];
        //  var getKeys = function(obj){
        var keys = [];
        // for(var key in data){
        //    keys.push(key);
        // }

        // for (var property in data) {
        //     if (data.hasOwnProperty(property)) {

        //         keys.push(property);                }
        // }

        // return keys;
        //    }
        var one = Object.getOwnPropertyNames(data[0])[0];
        var two = Object.getOwnPropertyNames(data[0])[1];
        var three = Object.getOwnPropertyNames(data[0])[2];
        var four = Object.getOwnPropertyNames(data[0])[3];
        for (let i = 1; i < data.length; i++) {
            // if(i==1)
            // {
            t1x.push([data[i][one]]);
            t1y.push([data[i][two]]);
            t2x.push([data[i][three]]);
            t2y.push([data[i][four]]);

            // t2x.push([Object.getOwnPropertyNames(data[0])[2]]);
            // t2y.push([Object.getOwnPropertyNames(data[0])[3]]);
            // a ="{ x: "+data[i].InnerI+", y: "+data[i].Innert+" }";
            // b ="{ x: "+data[i].OuterI+", y: "+data[i].Outert+" }";
            //    }
            //    else
            //    {ggggvxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxfddddddggggggggggggggggggggggggggggggggggggggbvhggggggggggggggggggggggggggggggfrrrrrrrrrdxc ggggggggggg
            //     if(data[i].InnerI !=null && data[i].Innert !="" && data[i].InnerI !=null && data[i].Innert !="")
            //     a =a +","+"{ x: "+data[i].InnerI+", y: "+data[i].Innert+" }";
            //     if(data[i].OuterI !=null && data[i].OuterI !="" && data[i].Outert !=null && data[i].Outert !="")
            //     b =b +","+"{ x: "+data[i].OuterI+", y: "+data[i].Outert+" }";
            //    }
        }

        let script = this._renderer2.createElement('script');
        script.type = `text/javascript`;
        script.text = `
            {
                var trace1 = {
                    x: [`+ t1x + `],
                    y: [`+ t1y + `],
                    type: 'line'
                  };
                  
                  var trace2 = {
                    x: [`+ t2x + `],
                    y: [`+ t2y + `],
                    type: 'line'
                  };
                  
                  var data = [trace1, trace2];
                  
                  var layout = {
                    xaxis: {
                      type: 'log',
                      autorange: true
                    },
                    yaxis: {
                      type: 'log',
                      autorange: true
                    }
                  };
                  
                  Plotly.newPlot('myDiv', data, layout);
                  

                 
                    // var chartjst = new CanvasJS.Chart("chartContainer", {
                    //     animationEnabled: true,
                    //     zoomEnabled: true,
                    //     theme: "dark2",
                    //     title: {
                    //         text: "Growth in Internet Users Globally"
                    //     },
                    //     axisX: {
                    //         title: "Year",
                    //         valueFormatString: "####",
                    //         interval: 2
                    //     },
                    //     axisY: {
                    //         logarithmic: true, //change it to false
                    //         title: "Internet Users (Log)",
                    //         titleFontColor: "#6D78AD",
                    //         lineColor: "#6D78AD",
                    //         gridThickness: 0,
                    //         lineThickness: 1,
                    //         labelFormatter: addSymbols
                    //     },
                    //     axisY2: {
                    //         title: "Internet Users",
                    //         titleFontColor: "#51CDA0",
                    //         logarithmic: false, //change it to true
                    //         lineColor: "#51CDA0",
                    //         gridThickness: 0,
                    //         lineThickness: 1,
                    //         labelFormatter: addSymbols
                    //     },
                    //     legend: {
                    //         verticalAlign: "top",
                    //         fontSize: 16,
                    //         dockInsidePlotArea: true
                    //     },
                    //     data: [{
                    //         type: "line",
                    //         xValueFormatString: "####",
                    //         showInLegend: true,
                    //         name: "Log Scale",
                    //         dataPoints: [
                    //             `+ a + `
                    //         ]
                    //     },
                    //     {
                    //         type: "line",
                    //         xValueFormatString: "####",
                    //         axisYType: "secondary",
                    //         showInLegend: true,
                    //         name: "Linear Scale",
                    //         dataPoints: [
                    //             `+ b + `
                    //         ]
                    //     }]
                    // });
                    // chartjst.render();
                    
                    // function addSymbols(e) {
                    //     var suffixes = ["", "K", "M", "B"];
                    
                    //     var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);
                    //     if(order > suffixes.length - 1)
                    //         order = suffixes.length - 1;
                    
                    //     var suffix = suffixes[order];
                    //     return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
                    // }

                    }
                
            
        `;

        this._renderer2.appendChild(this._document.body, script);
    }


    getChartLatest(data: any, dimsize: number) {

        if (data != undefined && data != null && data != "") {
            var a = "";
            var b = "";
            var t1x = [];
            var t1y = [];

            var t2x = [];
            var t2y = [];
            var t3x = [];
            var t3y = [];
            var keys = [];


            var length = (data.length);

            for (let i = 0; i < length - 1; (i = i + parseInt(dimsize.toString()))) {
                // if(i==1)
                // {

                if (parseFloat(data[i].Val) > 0)
                    t1x.push([parseFloat(data[i].Val).toFixed(4)]);
                if (parseFloat(data[i + 1].Val) > 0)
                    t1y.push([parseFloat(data[i + 1].Val).toFixed(4)]);
                if (parseFloat(data[i + 2].Val) > 0)
                    t2x.push([parseFloat(data[i + 2].Val).toFixed(4)]);
                if (parseFloat(data[i + 3].Val) > 0)
                    t2y.push([parseFloat(data[i + 3].Val).toFixed(4)]);
                if (dimsize > 4) {

                    if (parseFloat(data[i + 4].Val) > 0)
                        t3x.push([parseFloat(data[i + 4].Val).toFixed(4)]);
                    if (parseFloat(data[i + 5].Val) > 0)
                        t3y.push([parseFloat(data[i + 5].Val).toFixed(4)]);
                }


            }
            // var trace1 = {
            //     x: [`+t1x+`],
            //     y: [`+t1y+`],
            //     type: 'line', fill: 'tozeroy'
            //   };

            //   var trace2 = {
            //     x: [`+t2x+`],
            //     y: [`+t2y+`],
            //     type: 'line' , fill: 'tonexty'
            //   };
            //   var trace3 = {
            //     x: [`+t3x+`],
            //     y: [`+t3y+`],
            //     type: 'line' , fill: 'tonexty'
            //   };
            let script = this._renderer2.createElement('script');
            script.type = `text/javascript`;
            script.text = `
            {
                var trace1 = {
                    x: [`+ t1x + `],
                    y: [`+ t1y + `],
                    mode: 'lines'
                  };
                  
                  var trace2 = {
                    x: [`+ t2x + `],
                    y: [`+ t2y + `],
                    mode: 'lines' 
                   , fill:'tonexty'
                  };
                  var trace3 = {
                    x: [`+ t3x + `],
                    y: [`+ t3y + `],
                    mode: 'lines' 
                  };
                  
                  var data = [trace1, trace2, trace3];
                  
                  var layout = {
                //       title: {
                //     text:'Plot Title',
                //     font: {
                //       family: 'Courier New, monospace',
                //       size: 24
                //     },
                //     xref: 'paper',
                //     x: 0.05,
                //   },
                    xaxis: {
                        title: {
                            text: 'Current (A)',
                            font: {
                              family: 'sans-serif',
                              size: 14,
                              color: '#7f7f7f'
                            }
                          },
                      type: 'log',
                      autorange: true,
                    },
                    yaxis: {
                        title: {
                            text: 'Time (s)',
                            font: {
                              family: 'sans-serif',
                              size: 14,
                              color: '#7f7f7f'
                            }
                          },
                      type: 'log',
                      autorange: true
                    }
                  };
                  
                  Plotly.newPlot('myDiv', data, layout);
                  

                 
                    // var chartjst = new CanvasJS.Chart("chartContainer", {
                    //     animationEnabled: true,
                    //     zoomEnabled: true,
                    //     theme: "dark2",
                    //     title: {
                    //         text: "Growth in Internet Users Globally"
                    //     },
                    //     axisX: {
                    //         title: "Year",
                    //         valueFormatString: "####",
                    //         interval: 2
                    //     },
                    //     axisY: {
                    //         logarithmic: true, //change it to false
                    //         title: "Internet Users (Log)",
                    //         titleFontColor: "#6D78AD",
                    //         lineColor: "#6D78AD",
                    //         gridThickness: 0,
                    //         lineThickness: 1,
                    //         labelFormatter: addSymbols
                    //     },
                    //     axisY2: {
                    //         title: "Internet Users",
                    //         titleFontColor: "#51CDA0",
                    //         logarithmic: false, //change it to true
                    //         lineColor: "#51CDA0",
                    //         gridThickness: 0,
                    //         lineThickness: 1,
                    //         labelFormatter: addSymbols
                    //     },
                    //     legend: {
                    //         verticalAlign: "top",
                    //         fontSize: 16,
                    //         dockInsidePlotArea: true
                    //     },
                    //     data: [{
                    //         type: "line",
                    //         xValueFormatString: "####",
                    //         showInLegend: true,
                    //         name: "Log Scale",
                    //         dataPoints: [
                    //             `+ a + `
                    //         ]
                    //     },
                    //     {
                    //         type: "line",
                    //         xValueFormatString: "####",
                    //         axisYType: "secondary",
                    //         showInLegend: true,
                    //         name: "Linear Scale",
                    //         dataPoints: [
                    //             `+ b + `
                    //         ]
                    //     }]
                    // });
                    // chartjst.render();
                    
                    // function addSymbols(e) {
                    //     var suffixes = ["", "K", "M", "B"];
                    
                    //     var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);
                    //     if(order > suffixes.length - 1)
                    //         order = suffixes.length - 1;
                    
                    //     var suffix = suffixes[order];
                    //     return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
                    // }

                    }
                
            
        `;

            this._renderer2.appendChild(this._document.body, script);
        }
    }
    UpdateXmlValues(data: ProtectiveXmlInput) {
        this.showLoader();
        this._settingService.storeProtectiveXml(data).subscribe((result) => {
            this.hideLoader();
        }, error => {
            this.hideLoader();
        });
    }
    trimChar(string, charToRemove) {
        while (string.charAt(0) == charToRemove) {
            string = string.substring(1);
        }

        while (string.charAt(string.length - 1) == charToRemove) {
            string = string.substring(0, string.length - 1);
        }

        return string;
    }
    AcceptReport(savexml: string = "") {
        if (this.CheckFormValid()) {
            var a = $('#ddlManufacturer option:selected').val();
            var manufacturerId = parseInt(a.toString());
            var familyId = this.model.toString();
            var c = $('#ddlModel option:selected').val();
            var modelId = parseInt(c.toString());
            var d = $('#ddlrating option:selected').val();
            var ee = $('#ddlcurve option:selected').val();
            var curve = (ee.toString());


            var res = curve;
            if (curve.indexOf('-') != -1) {
                res = curve.substr(0, curve.lastIndexOf('-'));
                res = this.trimChar(res.trim(), "-");
            }
            // $('#electronic-device').val($('#ddlManufacturer option:selected').text() +", "
            // +$('#ddlFamily option:selected').text() +", "+
            // $('#ddlModel option:selected').text().split('-')[0]+", "+$('#ddlrating option:selected').text()+" A "
            // +", "+res);
            // $('#magneticonly-device').val($('#ddlManufacturer option:selected').text() +", "
            // +$('#ddlFamily option:selected').text() +", "+
            // $('#ddlModel option:selected').text().split('-')[0]+", "+$('#ddlrating option:selected').text() +" A "
            // +", "+res);
            // $('#thermomagnetic-device').val($('#ddlManufacturer option:selected').text() +", "
            // +$('#ddlFamily option:selected').text() +", "+
            // $('#ddlModel option:selected').text().split('-')[0]+", "+$('#ddlrating option:selected').text()+" A "
            // +", "+res);
            this.multiplierEIR = 1;
            this.multiplierIN = 1;
            this.multiplierEIO = 1;
            $('#mcbacb-device').text(($('#ddlManufacturer option:selected').text() + ", "
                + $('#ddlFamily option:selected').text() + ", " +
                $('#ddlModel option:selected').text().split('-')[0] + ", " + $('#ddlrating option:selected').text() + " A "
                + ", " + res).trim());
            var arr = this.model.split(' ')[1];
            if (arr != null && arr != "" && (arr == "MCB" || arr == "Fuse")) {


                $("#divmcbacbonly").show();
                $("#divelectronic").hide();
                $("#divmagneticonly").hide();
                $("#divtmagneticonly").hide();
                this.LoadControls(manufacturerId, familyId, modelId, d.toString(), curve, '', '', '', '', '', ''
                    , '', '', '', ''
                    , '', ''
                    , '', ''
                    , '', ''
                    , '', '', savexml);
            }
            else {

                $("#divmcbacbonly").hide();
                // if(this.deviceType=="1")
                // {

                //     var currenttolerance=$('#current-tolerance-range').val().toString();
                //     if($('#current-tolerance-rangehdn').val() !=null && $('#current-tolerance-rangehdn').val() !="")
                //     {
                //         currenttolerance=$('#current-tolerance-rangehdn').val().toString();
                //     }

                //     var timetolerance=$('#time-tolerance-range').val().toString();
                //     if($('#time-tolerance-rangehdn').val() !=null && $('#time-tolerance-rangehdn').val() !="")
                //     {
                //         timetolerance=$('#time-tolerance-rangehdn').val().toString();
                //     }

                //     var timetobreak=$('#time-tobreak-range').val().toString();
                //     if($('#time-tobreak-rangehdn').val() !=null && $('#time-tobreak-rangehdn').val() !="")
                //     {
                //         timetobreak=$('#time-tobreak-rangehdn').val().toString();
                //     }

                //     var eratingplug=$('#ratingplug').val().toString();
                //     var eltbool="0";
                //     if($("#chcklongtime").is(":checked"))
                //     {
                //         eltbool="1";
                //     }

                //     var estbool="0";
                //     if($("#chckshorttime").is(":checked"))
                //     {
                //         estbool="1";
                //     }

                //     var eintbool="0";
                //     if($("#chckInstant").is(":checked"))
                //     {
                //         eintbool="1";
                //     }

                //     var eio=$('#pickupcurrpre-range').val().toString();
                //     if($('#pickupcurrpre-rangehdn').val() !=null && $('#pickupcurrpre-rangehdn').val() !="")
                //     {
                //         eio=$('#pickupcurrpre-rangehdn').val().toString();
                //     }


                //     var eir=$('#pickupcurr-range').val().toString();
                //     if($('#pickupcurr-rangehdn').val() !=null && $('#pickupcurr-rangehdn').val() !="")
                //     {
                //         eir=$('#pickupcurr-rangehdn').val().toString();
                //     }


                //     var etr=$('#timedelay-range').val().toString();
                //     if($('#timedelay-rangehdn').val() !=null && $('#timedelay-rangehdn').val() !="")
                //     {
                //         etr=$('#timedelay-rangehdn').val().toString();
                //     }

                //     var eisd=$('#pickupshort-range').val().toString();
                //     if($('#pickupshort-rangehdn').val() !=null && $('#pickupshort-rangehdn').val() !="")
                //     {
                //         eisd=$('#pickupshort-rangehdn').val().toString();
                //     }


                //     var etsd=$('#timedelayshort-range').val().toString();
                //     if($('#timedelayshort-rangehdn').val() !=null && $('#timedelayshort-rangehdn').val() !="")
                //     {
                //         etsd=$('#timedelayshort-rangehdn').val().toString();
                //     }



                //     var ei2tcurve="0";
                //     if($("#chcki2curve").is(":checked"))
                //     {
                //         ei2tcurve="1";
                //     }


                // var eli=$('#pickupcurrinst-range').val().toString();
                //     if($('#pickupcurrinst-rangehdn').val() !=null && $('#pickupcurrinst-rangehdn').val() !="")
                //     {
                //         eli=$('#pickupcurrinst-rangehdn').val().toString();
                //     }
                // } 

                //     if(this.deviceType=="2")
                // {
                //     var tmir=$('#pickupcurrth').val().toString();
                //     if($('#pickupcurrthhdn').val() !=null && $('#pickupcurrthhdn').val() !="")
                //     {
                //         tmir=$('#pickupcurrthhdn').val().toString();
                //     }
                //     var tmim=$('#pickupcurrmg').val().toString();
                //     if($('#pickupcurrmgshdn').val() !=null && $('#pickupcurrmgshdn').val() !="")
                //     {
                //         tmim=$('#pickupcurrmgshdn').val().toString();
                //     }

                // }   
                // if(this.deviceType=="3")
                // {
                //     var mim=$('#pickupcurrmgpr').val().toString();
                //     if($('#pickupcurrmgprhdn').val() !=null && $('#pickupcurrmgprhdn').val() !="")
                //     {
                //         mim=$('#pickupcurrmgprhdn').val().toString();
                //     }
                // }   

                this.LoadControls(manufacturerId, familyId, modelId, d.toString(), curve, '', '', '', '', '', ''
                    , '', '', '', ''
                    , '', ''
                    , '', ''
                    , '', ''
                    , '', '', savexml);
                //   this.GetXml(manufacturerId,familyId,modelId,d.toString(),curve, tmir ,tmim, mim,currenttolerance, timetolerance,timetobreak
                //   , eratingplug,eltbool , estbool,eintbool
                //   , eio,eir
                //   , etr,eisd
                //   , etsd,ei2tcurve
                //   ,eli,1);
                //   $('#reportDlgVR').modal('hide');
            }
        }
    }

    updatem(controlchangedId: string = "", $event: any) {
        if ($event.toString() != "NaN") {
            var a = $('#ddlManufacturer option:selected').val();
            var manufacturerId = parseInt(a.toString());
            var familyId = this.model.toString();
            var c = $('#ddlModel option:selected').val();
            var modelId = parseInt(c.toString());
            var d = $('#ddlrating option:selected').val();
            var ee = $('#ddlcurve option:selected').val();
            var curve = (ee.toString());
            var multiplier = 1;
            var multipliercolumn = $(controlchangedId).attr('multiplier');
            if (multipliercolumn != undefined) {
                this.setMultiplier();
                if (multipliercolumn == "E_Io") {
                    multiplier = this.multiplierEIO;
                }
                if (multipliercolumn == "E_Ir") {
                    multiplier = this.multiplierEIR;

                }
                if (multipliercolumn == "In") {
                    multiplier = this.multiplierIN;

                }
            }


            if (controlchangedId == "#pickupcurrmgpr") {
                this.slidermim = $event;
                this.slidermimcalc = this.RemoveTrailingZeros((Math.round((parseFloat(this.getLegendFromValue(this.slidermim, this.slidermimoptions)) * multiplier) * 100) / 100).toFixed(2));
            }


            var mim = this.getLegendFromValue(this.slidermim, this.slidermimoptions)

            this.GetXml(manufacturerId, familyId, modelId, d.toString(), curve, '', '', mim, '', '', ''
                , '', '', '', ''
                , '', ''
                , '', ''
                , '', ''
                , '', 0, controlchangedId, '');
        }
    }
    updateTm(controlchangedId: string = "", $event: any) {

        if ($event.toString() != "NaN") {
            var a = $('#ddlManufacturer option:selected').val();
            var manufacturerId = parseInt(a.toString());
            var familyId = this.model.toString();
            var c = $('#ddlModel option:selected').val();
            var modelId = parseInt(c.toString());
            var d = $('#ddlrating option:selected').val();
            var ee = $('#ddlcurve option:selected').val();
            var curve = (ee.toString());

            var multiplier = 1;
            var multipliercolumn = $(controlchangedId).attr('multiplier');
            if (multipliercolumn != undefined) {
                this.setMultiplier();
                if (multipliercolumn == "E_Io") {
                    multiplier = this.multiplierEIO;
                }
                if (multipliercolumn == "E_Ir") {
                    multiplier = this.multiplierEIR;

                }
                if (multipliercolumn == "In") {
                    multiplier = this.multiplierIN;

                }
            }


            if (controlchangedId == "#pickupcurrth") {
                this.slidertmir = $event;
                this.slidertmircalc = this.RemoveTrailingZeros((Math.round((parseFloat(this.getLegendFromValue(this.slidertmir, this.slidertmiroptions)) * multiplier) * 100) / 100).toFixed(2));
            }
            if (controlchangedId == "#pickupcurrmg") {
                this.slidertmim = $event;
                this.slidertmimcalc = this.RemoveTrailingZeros((Math.round((parseFloat(this.getLegendFromValue(this.slidertmim, this.slidertmimoptions)) * multiplier) * 100) / 100).toFixed(2));

            }
            if (this.deviceType == "2") {
                var tmir = this.getLegendFromValue(this.slidertmir, this.slidertmiroptions);

                var tmim = this.getLegendFromValue(this.slidertmim, this.slidertmimoptions);


            }
            this.GetXml(manufacturerId, familyId, modelId, d.toString(), curve, tmir, tmim, '', '', '', ''
                , '', '', '', ''
                , '', ''
                , '', ''
                , '', ''
                , '', 0, controlchangedId, '');
        }
    }
    getCalculatedResult(event: string, multiplier: number) {
        try {
            let isnum = /^\d+(\.\d{1,2})?$/.test(event);
            if (!isnum) {
                return event;

            }
            else {
                return this.RemoveTrailingZeros((Math.round((parseFloat(event) * multiplier) * 100) / 100).toFixed(2));

            }
        }
        catch (err) {
            return event;

        }

    }
    updateElectronic2(controlchangedId: string = "", $event: any) {

        if ($event.toString() != "NaN") {

            var a = $('#ddlManufacturer option:selected').val();
            var manufacturerId = parseInt(a.toString());
            var familyId = this.model.toString();
            var c = $('#ddlModel option:selected').val();
            var modelId = parseInt(c.toString());
            var d = $('#ddlrating option:selected').val();
            var ee = $('#ddlcurve option:selected').val();
            var curve = (ee.toString());



            var multiplier = 1;
            var multipliercolumn = $(controlchangedId).attr('multiplier');
            if (multipliercolumn != undefined) {
                this.setMultiplier();
                if (multipliercolumn == "E_Io") {
                    multiplier = this.multiplierEIO;
                }
                if (multipliercolumn == "E_Ir") {
                    multiplier = this.multiplierEIR;

                }
                if (multipliercolumn == "In") {
                    multiplier = this.multiplierIN;

                }
            }
            if (controlchangedId == "#timedelayshort-range") {
                this.slideretsd1 = $event;
                this.slideretsdcalc = this.getCalculatedResult(this.getLegendFromValue(this.slideretsd1, this.slideretsdoptions), multiplier);

            }


            var currenttolerance = this.getLegendFromValue(this.slidercurrenttolerance, this.slidercurrenttoleranceoptions);
            if ($("#current-tolerance-range").hasClass("hastype3")) {
                currenttolerance = (parseFloat(currenttolerance) / this.multiplierIN).toFixed(2).toString();
            }

            var timetolerance = this.getLegendFromValue(this.slidertimetolerance, this.slidertimetoleranceoptions);
            if ($("#time-tolerance-range").hasClass("hastype3")) {
                timetolerance = (parseFloat(timetolerance) / this.multiplierIN).toFixed(2).toString();
            }

            var timetobreak = this.getLegendFromValue(this.slidertimetobreak, this.slidertimetobreakoptions);

            if ($("#time-tobreak-range").hasClass("hastype3")) {
                timetobreak = (parseFloat(timetobreak) / this.multiplierIN).toFixed(2).toString();
            }
            var eratingplug = "";
            if ($('#ratingplug option:selected').val() != undefined)
                eratingplug = $('#ratingplug option:selected').val().toString();//$('#ratingplug').val().toString();
            var eltbool = "0";
            if ($("#chcklongtime").is(":checked") && $('#ratingplug option:selected').val() != "OFF") {
                eltbool = "1";
            }


            var estbool = "0";
            if ($("#chckshorttime").is(":checked")) {
                estbool = "1";
            }
            else {
                $("#chcki2curve").prop("checked", false);
            }
            var eintbool = "0";
            if ($("#chckInstant").is(":checked")) {
                eintbool = "1";
            }



            var eio = this.getLegendFromValue(this.slidereio, this.slidereiooptions)
                ;
            if ($("#pickupcurrpre-range").hasClass("hastype3")) {
                eio = (parseFloat(eio) / this.multiplierIN).toFixed(2).toString();
            }
            var eir = this.getLegendFromValue(this.slidereir, this.slidereiroptions)
                ;

            if ($("#pickupcurr-range").hasClass("hastype3")) {
                eir = (parseFloat(eir) / this.multiplierIN).toFixed(2).toString();
            }
            var etr = this.getLegendFromValue(this.slideretr, this.slideretroptions)
                ;
            if ($("#timedelay-range").hasClass("hastype3")) {
                etr = (parseFloat(etr) / this.multiplierIN).toFixed(2).toString();
            }
            var idmtl = this.getLegendFromValue(this.slideridmtl, this.slideridmtloptions)
                ;
            if ($("#idmtl-settings").hasClass("hastype3")) {
                idmtl = (parseFloat(idmtl) / this.multiplierIN).toFixed(2).toString();
            }
            var eisd = this.getLegendFromValue(this.slidereisd, this.slidereisdoptions)
                ;
            if ($("#pickupshort-range").hasClass("hastype3")) {
                eisd = (parseFloat(eisd) / this.multiplierIN).toFixed(2).toString();
            }
            var etsd = this.getLegendFromValue(this.slideretsd1, this.slideretsdoptions)
                ;
            if ($("#timedelayshort-range").hasClass("hastype3")) {
                etsd = (parseFloat(etsd) / this.multiplierIN).toFixed(2).toString();
            }

            var ei2tcurve = "0";
            if ($("#chcki2curve").is(":checked")) {
                ei2tcurve = "1";
            }
            var eli = this.getLegendFromValue(this.slidereli, this.sliderelioptions);

            if ($("#pickupcurrinst-range").hasClass("hastype3")) {
                eli = (parseFloat(eli) / this.multiplierIN).toFixed(2).toString();
            }






            this.GetXml(manufacturerId, familyId, modelId, d.toString(), curve, '', '', '', currenttolerance, timetolerance, timetobreak
                , eratingplug, eltbool, estbool, eintbool
                , eio, eir
                , etr, eisd
                , etsd, ei2tcurve
                , eli, 0, controlchangedId, idmtl);
        }
    }


    updateElectronic(controlchangedId: string = "", $event: any) {
        // $(idofcntrltoset).text($(idofcntrl).val().toString());
        if ($event.toString() != "NaN") {
            var a = $('#ddlManufacturer option:selected').val();
            var manufacturerId = parseInt(a.toString());
            var familyId = this.model.toString();
            var c = $('#ddlModel option:selected').val();
            var modelId = parseInt(c.toString());
            var d = $('#ddlrating option:selected').val();
            var ee = $('#ddlcurve option:selected').val();
            var curve = (ee.toString());



            var multiplier = 1;
            var multipliercolumn = $(controlchangedId).attr('multiplier');
            if (multipliercolumn != undefined) {
                this.setMultiplier();
                if (multipliercolumn == "E_Io") {
                    multiplier = this.multiplierEIO;
                }
                if (multipliercolumn == "E_Ir") {
                    multiplier = this.multiplierEIR;

                }
                if (multipliercolumn == "In") {
                    multiplier = this.multiplierIN;
                }
            }


            if (controlchangedId == "#current-tolerance-range") {
                this.slidercurrenttolerance = $event;
                this.slidercurrenttolerancecalc = Math.round(100 * parseFloat(this.getCalculatedResult
                    (this.getLegendFromValue($event, this.slidercurrenttoleranceoptions), multiplier))).toString();
            }
            if (controlchangedId == "#time-tolerance-range") {
                this.slidertimetolerance = $event;
                this.slidertimetolerancecalc = Math.round(100 * parseFloat(this.getCalculatedResult(this.getLegendFromValue($event
                    , this.slidertimetoleranceoptions), multiplier))).toString();

            }

            if (controlchangedId == "#time-tobreak-range") {
                this.slidertimetobreak = $event;
                this.slidertimetobreakcalc = this.getCalculatedResult(this.getLegendFromValue($event
                    , this.slidertimetobreakoptions), multiplier);
            }
            if (controlchangedId == "#pickupcurrpre-range") {

                this.slidereio = $event;
                this.slidereiocalc = this.getCalculatedResult(this.getLegendFromValue($event
                    , this.slidereiooptions), multiplier);
                this.multiplierEIO = parseFloat(this.slidereiocalc);
                var elementonwhichevents = "";
                $(".multiplier").each(function () {
                    var a = $(this);
                    if ($(a).attr('multiplier') == "E_Io") {
                        elementonwhichevents = elementonwhichevents + "," + $(a).attr("id");
                    }
                });
                var elementonwhicheventsarry = elementonwhichevents.split(',');
                for (var i = 0; i < elementonwhicheventsarry.length; i++) {
                    if (elementonwhicheventsarry[i] != "") {
                        if (elementonwhicheventsarry[i] == "current-tolerance-range") {
                            this.slidercurrenttolerancecalc = Math.round(100 * parseFloat(this.getCalculatedResult
                                (this.getLegendFromValue(this.slidercurrenttolerance, this.slidercurrenttoleranceoptions), this.multiplierEIO))).toString();
                        }
                        if (elementonwhicheventsarry[i] == "time-tolerance-range") {
                            this.slidertimetolerancecalc = Math.round(100 * parseFloat(this.getCalculatedResult(this.getLegendFromValue(this.slidertimetolerance, this.slidertimetoleranceoptions)
                                , this.multiplierEIO))).toString();

                        }
                        if (elementonwhicheventsarry[i] == "time-tobreak-range") {
                            this.slidertimetobreakcalc = this.getCalculatedResult(this.getLegendFromValue(this.slidertimetobreak, this.slidertimetobreakoptions)
                                , this.multiplierEIO);
                        }

                        if (elementonwhicheventsarry[i] == "pickupcurr-range") {
                            // this.slidereir=event;
                            // this.multiplierEIR=event;
                            this.slidereircalc = this.getCalculatedResult(this.getLegendFromValue(this.slidereir, this.slidereiroptions)
                                , this.multiplierEIO);
                        }
                        if (elementonwhicheventsarry[i] == "idmtl-settings") {

                            this.slideridmtlcalc = this.getCalculatedResult(this.getLegendFromValue(this.slideridmtl, this.slideridmtloptions)
                                , this.multiplierEIO);
                        }
                        if (elementonwhicheventsarry[i] == "timedelay-range") {
                            this.slideretrcalc = this.getCalculatedResult(this.getLegendFromValue(this.slideretr, this.slideretroptions)
                                , this.multiplierEIO);


                        }
                        if (elementonwhicheventsarry[i] == "pickupshort-range") {
                            this.slidereisdcalc = this.getCalculatedResult(
                                this.getLegendFromValue(this.slidereisd, this.slidereisdoptions), this.multiplierEIO);

                        }
                        if (elementonwhicheventsarry[i] == "timedelayshort-range") {

                            this.slideretsdcalc = this.getCalculatedResult(this.getLegendFromValue(this.slideretsd1, this.slideretsdoptions)
                                , this.multiplierEIO);

                        }
                        if (elementonwhicheventsarry[i] == "pickupcurrinst-range") {

                            this.sliderelicalc = this.getCalculatedResult(this.getLegendFromValue(this.slidereli, this.sliderelioptions)
                                , this.multiplierEIO);

                        }
                    }
                }

            }

            if (controlchangedId == "#pickupcurr-range") {
                this.slidereir = $event;
                this.slidereircalc = this.getCalculatedResult(this.getLegendFromValue(this.slidereir, this.slidereiroptions)
                    , multiplier);

                this.multiplierEIR = parseFloat(this.slidereircalc);

                var elementonwhichevents = "";
                $(".multiplier").each(function () {
                    var a = $(this);
                    if ($(a).attr('multiplier') == "E_Ir") {
                        elementonwhichevents = elementonwhichevents + "," + $(a).attr("id");
                    }
                });
                var elementonwhicheventsarry = elementonwhichevents.split(',');
                for (var i = 0; i < elementonwhicheventsarry.length; i++) {
                    if (elementonwhicheventsarry[i] != "") {
                        if (elementonwhicheventsarry[i] == "current-tolerance-range") {
                            this.slidercurrenttolerancecalc = Math.round(100 * parseFloat(this.getCalculatedResult(this.getLegendFromValue(this.slidercurrenttolerance, this.slidercurrenttoleranceoptions)
                                , this.multiplierEIR))).toString();
                        }
                        if (elementonwhicheventsarry[i] == "time-tolerance-range") {
                            this.slidertimetolerancecalc = Math.round(100 * parseFloat(this.getCalculatedResult(this.getLegendFromValue($event
                                , this.slidertimetoleranceoptions), this.multiplierEIR))).toString();



                        }
                        if (elementonwhicheventsarry[i] == "time-tobreak-range") {
                            this.slidertimetobreakcalc = this.getCalculatedResult(this.getLegendFromValue(this.slidertimetobreak, this.slidertimetobreakoptions)
                                , this.multiplierEIR);
                        }

                        if (elementonwhicheventsarry[i] == "pickupcurrpre-range") {
                            // this.slidereir=event;
                            // this.multiplierEIR=event;
                            this.slidereiocalc = this.getCalculatedResult(this.getLegendFromValue(this.slidereio, this.slidereiooptions)
                                , this.multiplierEIR);
                        }
                        if (elementonwhicheventsarry[i] == "idmtl-settings") {
                            this.slideridmtlcalc = this.getCalculatedResult(this.getLegendFromValue(this.slideridmtl, this.slideridmtloptions)
                                , this.multiplierEIR);


                        }
                        if (elementonwhicheventsarry[i] == "timedelay-range") {
                            this.slideretrcalc = this.getCalculatedResult(this.getLegendFromValue(this.slideretr, this.slideretroptions)
                                , this.multiplierEIR);


                        }
                        if (elementonwhicheventsarry[i] == "pickupshort-range") {
                            this.slidereisdcalc = this.getCalculatedResult(this.getLegendFromValue(this.slidereisd, this.slidereisdoptions)
                                , this.multiplierEIR);

                        }
                        if (elementonwhicheventsarry[i] == "timedelayshort-range") {
                            this.slideretsdcalc = this.getCalculatedResult(this.getLegendFromValue(this.slideretsd1, this.slideretsdoptions)
                                , this.multiplierEIR);

                        }
                        if (elementonwhicheventsarry[i] == "pickupcurrinst-range") {

                            this.sliderelicalc = this.getCalculatedResult(this.getLegendFromValue(this.slidereli, this.sliderelioptions)
                                , this.multiplierEIR);

                        }
                    }
                }

            }
            if (controlchangedId == "#timedelay-range") {
                this.slideretr = $event;
                this.slideretrcalc = this.getCalculatedResult(this.getLegendFromValue(this.slideretr, this.slideretroptions), multiplier);


            }
            if (controlchangedId == "#idmtl-settings") {
                this.slideridmtl = $event;
                this.slideridmtlcalc = this.getCalculatedResult(this.getLegendFromValue(this.slideridmtl, this.slideridmtloptions)
                    , multiplier);


            }

            if (controlchangedId == "#pickupshort-range") {
                this.slidereisd = $event;
                this.slidereisdcalc = this.getCalculatedResult(this.getLegendFromValue(this.slidereisd, this.slidereisdoptions)
                    , multiplier);

            }
            if (controlchangedId == "#pickupcurrinst-range") {
                this.slidereli = $event;
                this.sliderelicalc = this.getCalculatedResult(this.getLegendFromValue(this.slidereli, this.sliderelioptions)
                    , multiplier);

            }

            var currenttolerance = this.getLegendFromValue(this.slidercurrenttolerance, this.slidercurrenttoleranceoptions);
            if ($("#current-tolerance-range").hasClass("hastype3")) {
                currenttolerance = (parseFloat(currenttolerance) / this.multiplierIN).toFixed(2).toString();
            }

            var timetolerance = this.getLegendFromValue(this.slidertimetolerance, this.slidertimetoleranceoptions);
            if ($("#time-tolerance-range").hasClass("hastype3")) {
                timetolerance = (parseFloat(timetolerance) / this.multiplierIN).toFixed(2).toString();
            }

            var timetobreak = this.getLegendFromValue(this.slidertimetobreak, this.slidertimetobreakoptions);

            if ($("#time-tobreak-range").hasClass("hastype3")) {
                timetobreak = (parseFloat(timetobreak) / this.multiplierIN).toFixed(2).toString();
            }
            var eratingplug = "";
            if ($('#ratingplug option:selected').val() != undefined)
                eratingplug = $('#ratingplug option:selected').val().toString();//$('#ratingplug').val().toString();
            var eltbool = "0";
            if ($("#chcklongtime").is(":checked") && $('#ratingplug option:selected').val() != "OFF") {
                eltbool = "1";
            }

            var estbool = "0";
            if ($("#chckshorttime").is(":checked")) {
                estbool = "1";
            }
            else {
                $("#chcki2curve").prop("checked", false);
            }
            var eintbool = "0";
            // alert($("#chckInstant").is(":checked"));
            if ($("#chckInstant").is(":checked")) {
                eintbool = "1";
            }


            var eio = this.getLegendFromValue(this.slidereio, this.slidereiooptions)
                ;
            if ($("#pickupcurrpre-range").hasClass("hastype3")) {
                eio = (parseFloat(eio) / this.multiplierIN).toFixed(2).toString();
            }
            var eir = this.getLegendFromValue(this.slidereir, this.slidereiroptions)
                ;

            if ($("#pickupcurr-range").hasClass("hastype3")) {
                eir = (parseFloat(eir) / this.multiplierIN).toFixed(2).toString();
            }
            var etr = this.getLegendFromValue(this.slideretr, this.slideretroptions)
                ;
            if ($("#timedelay-range").hasClass("hastype3")) {
                etr = (parseFloat(etr) / this.multiplierIN).toFixed(2).toString();
            }
            var idmtl = this.getLegendFromValue(this.slideridmtl, this.slideridmtloptions)
                ;
            if ($("#idmtl-settings").hasClass("hastype3")) {
                idmtl = (parseFloat(idmtl) / this.multiplierIN).toFixed(2).toString();
            }
            var eisd = this.getLegendFromValue(this.slidereisd, this.slidereisdoptions)
                ;
            if ($("#pickupshort-range").hasClass("hastype3")) {
                eisd = (parseFloat(eisd) / this.multiplierIN).toFixed(2).toString();
            }
            var etsd = this.getLegendFromValue(this.slideretsd1, this.slideretsdoptions)
                ;
            if ($("#timedelayshort-range").hasClass("hastype3")) {
                etsd = (parseFloat(etsd) / this.multiplierIN).toFixed(2).toString();
            }

            var ei2tcurve = "0";
            if ($("#chcki2curve").is(":checked")) {
                ei2tcurve = "1";
            }
            var eli = this.getLegendFromValue(this.slidereli, this.sliderelioptions);

            if ($("#pickupcurrinst-range").hasClass("hastype3")) {
                eli = (parseFloat(eli) / this.multiplierIN).toFixed(2).toString();
            }


            this.GetXml(manufacturerId, familyId, modelId, d.toString(), curve, '', '', '', currenttolerance, timetolerance, timetobreak
                , eratingplug, eltbool, estbool, eintbool
                , eio, eir
                , etr, eisd
                , etsd, ei2tcurve
                , eli, 0, controlchangedId, idmtl);
        }
    }


    CloseReport() {
        $('#reportDlgVR').modal('hide');
    }
    CloseCurveData() {
        $('#CurveDataDialog').modal('hide');
    }
    GetDate(str: any) {
        var arr = str.split('/');
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        var i = 0;
        for (i; i < months.length; i++) {

            if (months[i] == arr[1]) {
                break;
            }
        }
        var formatddate = (i + 1) + '/' + arr[0] + '/' + arr[2];
        return formatddate;
    }
    getCurrentLoginInformations(): void {
        this.user = this._appSessionService.user;
        this.premiumUser = this.user.premiumUser;
        this.planLevel = this.user.userStripePlanLevel;
        let isAdminUser = (this.user.id === 1 || this.user.id === 2);
        this.isAdminUser = isAdminUser;
        this.roleId = this._appSessionService.role.roleId;
        if (this.roleId == 1 || this.roleId == 2) {
            this.getproject();
        }
        let unRegisteredUser = false;
        let userStripeCustomerId = this.user.userStripeCustomerId;
        this.isPlanAddedManually = this.user.isPlanAddedManually;

        if (!isAdminUser) {
            if (userStripeCustomerId) { unRegisteredUser = true; }
    
            if (MomentHelper.IsSubscriptionDateExpired(this.user.expirationDate)) {
                this.hasPlanExpired = true;
                this.handleUnlicencesOrExpiredFlow(this.user, this.standard, this._companyAccountService, this._router);
            }
        };
    }

    Allowdecimalwithplaces(event: any, places: number) {
        var num = event.target.value;
        if (num.split('.').length > 0) {
            var beforezero = num.split('.')[0];
            if (beforezero == "") {
                num = "0" + num;
            }
        }
        var with2Decimals = 0;
        if (places == 2) {
            with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/) != null ? num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] : event.target.value;

        }
        else if (places == 3) {
            with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,3})?/) != null ? num.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : event.target.value;

        }
        else if (places == 4) {
            with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,4})?/) != null ? num.toString().match(/^-?\d+(?:\.\d{0,4})?/)[0] : event.target.value;

        }

        event.target.value = with2Decimals
        //this.efficiency=event.target.value;
    }

    tabChange(evt, tabId) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(tabId).style.display = "block";
        evt.currentTarget.className += " active";

        if (tabId == "tab6" || tabId == "tab8") {
            this.hideSubmit = true;
            $('[data-toggle="tooltip"]').tooltip();
        }
        else
            this.hideSubmit = false;
    }

    addReportsFields() {

        if ($('#reports tr').length > 3) {
            $('#custom-restriction-model').modal('show');

            return false;
        }
        if(this.settingInput.customFields !=null && this.settingInput.customFields != undefined )
        {
            for (var j = 0; j < this.settingInput.customFields.length; j++) {
            
                this.settingInput.customFields[j].isSelected = false;
            }
        }
        let customereportFieldsInput: CustomereportFieldsInput = new CustomereportFieldsInput();

        if (this.settingInput.customFields === undefined) {
            this.settingInput.customFields = this.customFields;
        }
        customereportFieldsInput.isSelected = true;
        this.settingInput.customFields.push(customereportFieldsInput);
        this.isHighlightRow = 1;
       
        $('#reports tr').css('background-color', "");
        //$('#reports tr:last').css('background-color', '#007bff');
        // $(this).css('background-color', '#007bff').siblings().css("background-color", "");
    }

    deleteReportsFields(index) {
    //    if (this.indextoDelete < 0 || this.indextoDelete == null) {
            this.indextoDelete = this.settingInput.customFields.findIndex(x => x.isSelected == true);
            index = this.indextoDelete;
            //return;
     //   }

        if (this.indextoDelete >= 0) {

            this.settingInput.customFields.splice(this.indextoDelete, 1);

            if (this.settingInput.customFields.length > 0) {
                this.indextoDelete = Math.min(index, this.settingInput.customFields.length - 1);
                for (var j = 0; j < this.settingInput.customFields.length; j++) {
                    if (j == this.indextoDelete)
                        this.settingInput.customFields[j].isSelected = true;
                    else
                        this.settingInput.customFields[j].isSelected = false;
                }
            } else {
                this.indextoDelete = null; // No rows left
            }

            // this.indextoDelete = -1;
            return;
        }
    }

    onTableRowClick() {

        $(document).on('click', '#reports tr', function () {

            //alert();
            $(this).css('background-color', '#007bff').siblings().css("background-color", "");
        });
    }

    save(): void {
        if (this.hasPlanExpired) { this.handleUnlicencesOrExpiredFlow(this.user, this.standard, this._companyAccountService, this._router); }
        else {
            if (this.settingInput.companyLogo_ShowLogo === false) {
                this.settingInput.companyLogo_FileName = '';
                this.settingInput.companyLogo_LogoUrl = '';
            }
            this.SaveSettings();
        }
    }

    SaveSettings(): void {
        this._settingService.createSetting(this.settingInput).subscribe(result => {
            this._router.navigate(['/app/main/projects/']);
        });
    }

    onNeutralCondSelect() {
        $('#drpNeutralCond').on('change', (e) => {
            this.settingInput.voltageDrop_NeutralCondTempMode = parseInt((e.target as HTMLOptionElement).value);

        });
    }

    onDrpUnitSelect() {

        $('#drpunit').on('change', (e) => {
            this.settingInput.load_UnitValue = parseInt((e.target as HTMLOptionElement).value);

        });
    }
    showProtectiveDeviceUI() {
        this.FilterBreaking = 5;
        this.xmlData = "";

        $('#reportDlgVR').modal('show');

    }
    showCurveDataUI() {

        //     if(this.deviceType=="2")
        //     {
        //         this.showupdatedSetting('#pickupcurrth','#gridSizeValue');
        //     this.showupdatedSetting('#pickupcurrmg','#pickupcurrmgspn');

        //     }   
        //     else if(this.deviceType=="3")
        //     {
        //         this.showupdatedSetting('#pickupcurrmgpr','#pickupcurrmgprspn');

        //     }   
        //    else
        //    {




        //     this.showupdatedSetting('#current-tolerance-range','#current-tolerance-rangespn');
        //     this.showupdatedSetting('#time-tolerance-range','#time-tolerance-rangespn');
        //     this.showupdatedSetting('#time-tobreak-range','#time-tobreak-rangespn');
        //     this.showupdatedSetting('#pickupcurrpre-range','#pickupcurrpre-rangespn');
        //     this.showupdatedSetting('#pickupcurr-range','#pickupcurr-rangespn');                
        //     this.showupdatedSetting('#timedelay-range','#timedelay-rangespn');
        //     this.showupdatedSetting('#pickupshort-range','#pickupshort-rangespn');
        //     this.showupdatedSetting('#timedelayshort-range','#timedelayshort-rangespn');
        //     this.showupdatedSetting('#curve-range','#curve-rangespn');
        //     this.showupdatedSetting('#pickupcurrinst-range','#pickupcurrinst-rangespn');
        //    }
        $('#CurveDataDialog').modal('show');
    }
    GetFamilyGroupByManufacturerId(manufacturerId: number, pageload: boolean = false) {

        this._settingService.getFamilyByManufacturer(manufacturerId).subscribe(result => {

            if (result !== undefined && result !== null) {
                this.selectedFamily = result[0].items[0];

                this.familyOutput = result[0].items;
                this.model = result[0].items[0].familyid;

                this.defaultFamilyId = this.model;
                if (this.loadProtectiveXmlInput != null && this.loadProtectiveXmlInput.range_id != null && this.loadProtectiveXmlInput.range_id != "") {
                    this.model = this.loadProtectiveXmlInput.range_id;
                    this.defaultFamilyId = this.model;
                    if ($("#ddlManufacturer option:selected").text().trim() != "Generic") {
                        var name = $("#ddlManufacturer option:selected").text().trim() + "logo";
                        $('#divlogo img').attr('src', 'assets/cablepro/logo/' + name + '.svg');
                    }
                    else {
                        $('#divlogo img').attr('src', 'assets/cablepro/logo/a.png');

                    }
                }

                if (this.model != null && this.model != "") {
                    var arr = this.model.split(' ')[1];
                    if (arr != null && arr != "" && (arr == "MCCB" || arr == "ACB")) {
                        this.selectListFive = "Trip unit";
                    }
                    else if (arr != null && arr != "" && arr == "Fuse") {
                        this.selectListFive = "Fuse type";
                    }
                    else {
                        this.selectListFive = "Curve class";
                    }
                }


                // if(this.familyOutput.length >0)
                // {  

                this.GetModelGroupByManufacturerIdnew(manufacturerId, this.model, this.FilterBreaking);
                //}


            }

        },
            err => {

            });
    }


    GetBreakingByManufacturerId(manufacturerId: number, familyId: string, modelId: number, rating: string, curve: string) {
        this._settingService.getBreakingByManufacturerId(manufacturerId, familyId, modelId, rating, curve).subscribe(result => {
            if (result !== undefined && result !== null) {
                this.breakingOutput = result[0].items;


            }
        },
            err => {

            });
    }

    GetRatingByManufacturerId(manufacturerId: number, familyId: string, modelId: number, triggerEvent: boolean) {
        this.mcbOutput = [];

        this._settingService.getRatingByManufacturer(manufacturerId, familyId, modelId).subscribe(result => {
            if (result !== undefined && result !== null) {
                this.mcbOutput = result[0].items;
                this.defaultRatingId = (this.mcbOutput[0].ina.toString());
                if (this.loadProtectiveXmlInput != null && this.loadProtectiveXmlInput.rating != null && this.loadProtectiveXmlInput.rating != "") {
                    this.defaultRatingId = this.loadProtectiveXmlInput.rating;
                    // this.GetCurveByManufacturerId(manufacturerId,familyId,this.defaultModelId,true,this.defaultRatingId);
                }


                if (triggerEvent && this.mcbOutput.length > 0) {
                    this.GetCurveByManufacturerId(manufacturerId, familyId, modelId, true, this.defaultRatingId);
                }
            }
        },
            err => {

            });
    }

    GetOperationalTableByManufacturerId(manufacturerId: number, familyId: string, modelId: number, rating: number, curve: string) {
        // this._settingService.getOperationalTableByManufacturer(manufacturerId,familyId,modelId,rating,curve).subscribe(result => {
        //     if (result !== undefined && result !== null) {
        //         this.operationalTableOutput = result[0].items;


        //     }
        // },
        //     err => {

        //     });
    }
    setOptions(controlid: string, defaultarr: any) {
        if (controlid == "#pickupcurrth") {
            this.slidertmiroptions = defaultarr;
        }
        else if (controlid == "#pickupcurrmg") {
            this.slidertmimoptions = defaultarr;

        }
        else if (controlid == "#pickupcurrmgpr") {
            this.slidermimoptions = defaultarr;

        }



        if (controlid == "#current-tolerance-range") {
            this.slidercurrenttoleranceoptions = defaultarr;
        }
        else if (controlid == "#time-tolerance-range") {
            this.slidertimetoleranceoptions = defaultarr;

        }
        else if (controlid == "#time-tobreak-range") {
            this.slidertimetobreakoptions = defaultarr;

        }

        if (controlid == "#pickupcurrpre-range") {
            this.slidereiooptions = defaultarr;
        }
        else if (controlid == "#pickupcurr-range") {
            this.slidereiroptions = defaultarr;

        }
        else if (controlid == "#idmtl-settings") {
            this.slideridmtloptions = defaultarr;

        }

        else if (controlid == "#timedelay-range") {
            this.slideretroptions = defaultarr;

        }

        else if (controlid == "#pickupshort-range") {
            this.slidereisdoptions = defaultarr;

        }
        else if (controlid == "#timedelayshort-range") {
            this.slideretsdoptions = defaultarr;

        }
        else if (controlid == "#pickupcurrinst-range") {
            this.sliderelioptions = defaultarr;

        }
    }
    setDefaultValues(controlid: string, defaultvalue: string) {

        if (controlid == "#pickupcurrth") {
            this.slidertmir = this.getValueFromLegend(defaultvalue, this.slidertmiroptions);
        }
        else if (controlid == "#pickupcurrmg") {
            this.slidertmim = this.getValueFromLegend(defaultvalue, this.slidertmimoptions);

        }
        else if (controlid == "#pickupcurrmgpr") {
            this.slidermim = this.getValueFromLegend(defaultvalue, this.slidermimoptions);

        }

        else if (controlid == "#idmtl-settings") {
            this.slideridmtl = this.getValueFromLegend(defaultvalue, this.slideridmtloptions);

        }


        if (controlid == "#current-tolerance-range") {
            this.slidercurrenttolerance = this.getValueFromLegend(defaultvalue, this.slidercurrenttoleranceoptions);
        }
        if (controlid == "#time-tolerance-range") {
            this.slidertimetolerance = this.getValueFromLegend(defaultvalue, this.slidertimetoleranceoptions);

        }

        if (controlid == "#time-tobreak-range") {
            this.slidertimetobreak = this.getValueFromLegend(defaultvalue, this.slidertimetobreakoptions);
        }
        if (controlid == "#pickupcurrpre-range") {
            this.slidereio = this.getValueFromLegend(defaultvalue, this.slidereiooptions);

        }

        if (controlid == "#pickupcurr-range") {
            this.slidereir = this.getValueFromLegend(defaultvalue, this.slidereiroptions);
        }
        if (controlid == "#timedelay-range") {
            this.slideretr = this.getValueFromLegend(defaultvalue, this.slideretroptions);

        }
        if (controlid == "#pickupshort-range") {
            this.slidereisd = this.getValueFromLegend(defaultvalue, this.slidereisdoptions);

        }
        if (controlid == "#timedelayshort-range") {

            this.slideretsd1 = this.getValueFromLegend(defaultvalue, this.slideretsdoptions);
        }
        if (controlid == "#pickupcurrinst-range") {
            this.slidereli = this.getValueFromLegend(defaultvalue, this.sliderelioptions);

        }


    }
    setCalculatedValues(controlid: string, calcvalue: string) {
        if (controlid == "#pickupcurrth") {
            this.slidertmircalc = calcvalue;
        }
        else if (controlid == "#pickupcurrmg") {
            this.slidertmimcalc = calcvalue;

        }
        else if (controlid == "#pickupcurrmgpr") {
            this.slidermimcalc = calcvalue;

        }
        else if (controlid == "#idmtl-settings") {
            this.slideridmtlcalc = calcvalue;

        }

        if (controlid == "#current-tolerance-range") {
            this.slidercurrenttolerancecalc = Math.round(100 * parseFloat(calcvalue)).toString();
        }
        if (controlid == "#time-tolerance-range") {
            this.slidertimetolerancecalc = Math.round(100 * parseFloat(calcvalue)).toString();

        }

        if (controlid == "#time-tobreak-range") {
            this.slidertimetobreakcalc = calcvalue;
        }
        if (controlid == "#pickupcurrpre-range") {
            this.slidereiocalc = calcvalue;

        }

        if (controlid == "#pickupcurr-range") {
            this.slidereircalc = calcvalue;
        }
        if (controlid == "#timedelay-range") {
            this.slideretrcalc = calcvalue;

        }
        if (controlid == "#idmtl-settings") {
            this.slideridmtlcalc = calcvalue;

        }
        if (controlid == "#pickupshort-range") {
            this.slidereisdcalc = calcvalue;

        }
        if (controlid == "#timedelayshort-range") {

            this.slideretsdcalc = calcvalue;

        }
        if (controlid == "#pickupcurrinst-range") {
            this.sliderelicalc = calcvalue;

        }

    }


    RemoveTrailingZeros(numberWithZero: string): string {
        try {
            if (numberWithZero.indexOf('.') != -1) {
                var splitnumberList = numberWithZero.split('.');

                let afterDecimalValue = parseInt(splitnumberList[1]);

                if (afterDecimalValue < 10)
                    return parseFloat(numberWithZero).toString();
                else {
                    let modulusValue = afterDecimalValue % 10;

                    if (modulusValue == 0)
                        return parseFloat(numberWithZero).toFixed(1);
                    else
                        return parseFloat(numberWithZero).toFixed(2);
                }
            }
            else
                return parseInt(numberWithZero).toString();
        } catch (e) {
            return numberWithZero;

        }
    }
    getInputValues(str: string, controlid: string, controlspanid: string, controlhdn: string, controldiv: string, setvalue: number = 0, defaultvalue: string = "") {
        $(controlid).removeClass('hastype3');
        $(controlid).removeAttr('multiplier');
        if (str != null && str != "-" && str != "") {

            if (str.split(' ')[0].split('-')[0] == "Type1") {

                var val = str.split(' ')[0].split('-')[1].split(',');
                var Arr = [];
                var obj: any;
                var i = 0;
                val.forEach(element => {
                    obj = new Object();
                    obj.value = i++;
                    obj.legend = element.split(',')[0].toString();
                    Arr.push(obj);
                });
                // val.forEach(element => {
                // obj=new Object();
                //     obj.value =element.toString();
                //     Arr.push(obj);
                // });

                $(controlid).removeAttr('multiplier');
                this.setOptions(controlid, { stepsArray: Arr, showTicks: true });
                if (setvalue == 1) {
                    this.setDefaultValues(controlid, defaultvalue);
                    this.setCalculatedValues(controlid, defaultvalue);


                }
            }
            else if (str.split(' ')[0].split('-')[0] == "Type2") {
                var val = str.split(' ')[0].split('-')[1].split(',');
                var minval = val[0].split("=")[1];
                var maxval = val[1].split("=")[1];
                var step = val[2].split("=")[1];


                $(controlid).removeAttr('multiplier');
                this.setOptions(controlid, {
                    floor: minval,
                    ceil: maxval, step: step
                });

                if (setvalue == 1) {
                    this.setDefaultValues(controlid, defaultvalue);
                    this.setCalculatedValues(controlid, defaultvalue);

                }

            }
            else if (str.split(' ')[0].split('-')[0] == "Type3") {
                // $(controlid).removeAttr('multiplier');
                var val = str.split(' ')[0].split('-')[2].split(',');
                var multipliercolumn = str.split(' ')[0].split('-')[1];
                var multipliervalue = 1;
                var minval = val[0].split("=")[1];
                var maxval = val[1].split("=")[1];
                var step = val[2].split("=")[1];

                if (setvalue == 1) {

                    this.setMultiplier();
                    if (multipliercolumn == "E_Io") {
                        multipliervalue = this.multiplierEIO;
                    }
                    if (multipliercolumn == "E_Ir") {
                        multipliervalue = this.multiplierEIR;

                    }
                    if (multipliercolumn == "In") {
                        multipliervalue = this.multiplierIN;

                    }


                    this.setDefaultValues(controlid, (parseFloat(defaultvalue) * multipliervalue).toString());
                    this.setCalculatedValues(controlid, (parseFloat(defaultvalue) * multipliervalue).toString());
                    //  $(controlid).addClass('hastype3');  
                }

                this.setOptions(controlid, {
                    floor: (parseFloat(minval) * multipliervalue),
                    ceil: (parseFloat(maxval) * multipliervalue), step: step
                });

            }
            else if (str.split(' ')[0].split('-')[0] == "Type4") {
                var val = str.split(' ')[0].split('-')[2].split(',');
                var multipliercolumn = str.split(' ')[0].split('-')[1];
                var multipliervalue = 1;
                var Arr = [];
                var obj: any;
                // val.forEach(element => {
                // obj=new Object();
                //     obj.value =element.toString();
                //     Arr.push(obj);
                // });
                var i = 0;
                val.forEach(element => {
                    obj = new Object();
                    obj.value = i++;
                    obj.legend = element.split(',')[0].toString();
                    Arr.push(obj);
                });

                this.setOptions(controlid, { stepsArray: Arr, showTicks: true });

                if (setvalue == 1) {


                    this.setMultiplier();
                    if (multipliercolumn == "E_Io") {
                        multipliervalue = this.multiplierEIO;
                    }
                    if (multipliercolumn == "E_Ir") {
                        multipliervalue = this.multiplierEIR;

                    }
                    if (multipliercolumn == "In") {
                        multipliervalue = this.multiplierIN;
                    }

                    $(controlid).attr('multiplier', multipliercolumn);
                    $(controlid).addClass('multiplier');

                    this.setDefaultValues(controlid, defaultvalue);
                    this.setCalculatedValues(controlid, this.RemoveTrailingZeros((Math.round((parseFloat(defaultvalue) * multipliervalue) * 100) / 100).toFixed(2)));
                }


            }
            else if (str.split(' ')[0].split('-')[0] == "Type5") {
                var val = str.split(' ')[0].split('-')[2].split(',');
                var multipliercolumn = str.split(' ')[0].split('-')[1];

                var minval = val[0].split("=")[1];
                var maxval = val[1].split("=")[1];
                var step = val[2].split("=")[1];
                var multipliervalue = 1;


                this.setOptions(controlid, {
                    floor: minval,
                    ceil: maxval, step: step
                });


                if (setvalue == 1) {
                    this.setMultiplier();
                    if (multipliercolumn == "E_Io") {
                        multipliervalue = this.multiplierEIO;
                    }
                    if (multipliercolumn == "E_Ir") {
                        multipliervalue = this.multiplierEIR;

                    }
                    if (multipliercolumn == "In") {
                        multipliervalue = this.multiplierIN;

                    }
                    $(controlid).attr('multiplier', multipliercolumn);
                    $(controlid).addClass('multiplier');
                    this.setDefaultValues(controlid, defaultvalue);
                    this.setCalculatedValues(controlid, this.RemoveTrailingZeros((Math.round((parseFloat(defaultvalue) * multipliervalue) * 100) / 100).toFixed(2)));

                }



            }
            else if (str.split(' ')[0].split('-')[0] == "Type6") {
                var val = str.split('(')[0].split('-')[1].split(',');
                var Arr = [];
                var obj: any;
                $(controlid).removeAttr('multiplier');

                // obj=new Object();
                //     obj.value =element.toString();
                //     Arr.push(obj);
                // });
                var i = 0;
                val.forEach(element => {
                    obj = new Object();
                    obj.value = i++;
                    obj.legend = element.split(',')[0].toString();
                    Arr.push(obj);
                });

                this.setOptions(controlid, { stepsArray: Arr, showTicks: true });
                if (setvalue == 1) {
                    this.setDefaultValues(controlid, defaultvalue);
                    this.setCalculatedValues(controlid, defaultvalue);
                }

            }

            if (str.split(' ')[0].split('-')[0] == "Type7") {
                var val = str.split(' ')[0].split('-')[1].split(',,');
                var Arr = [];
                var obj: any;
                var i = 0;
                $(controlid).removeAttr('multiplier');
                val.forEach(element => {
                    obj = new Object();
                    obj.value = i++;
                    obj.legend = element.split(',')[0].toString();
                    Arr.push(obj);
                });
                this.setOptions(controlid, { stepsArray: Arr, showTicks: true, value: defaultvalue });
                if (setvalue == 1) {
                    this.setDefaultValues(controlid, defaultvalue);
                    this.setCalculatedValues(controlid, defaultvalue);
                }
            }
            $(controldiv).show();
        }
        else {
            defaultvalue = '-';
            this.setDefaultValues(controlid, defaultvalue);
            $(controldiv).hide();
        }
    }
    getValueFromLegend(showValue: string, arry: any): any {
        if (arry["showTicks"]) {
            if (showValue != "-") {
                let returnedValue: any = arry.stepsArray.find(e => {
                    return e['legend'] == showValue.toString();
                });
                if (returnedValue != null && returnedValue != undefined && isNaN(returnedValue) == false) {
                    return returnedValue["value"];
                }
                else {
                    return showValue;
                }
            }
        }
        else {
            return showValue;
        }
    }
    getLegendFromValue(showValue: string, arry: any): string {
        if (showValue == "NaN") {
            return "-";
        }
        if (arry["showTicks"]) {
            let returnedValue: any = arry.stepsArray.find(e => {
                return e.value == showValue;
            });

            if (returnedValue != null && returnedValue != undefined) {
                return returnedValue["legend"];
            }
            else {
                return showValue;
            }
        }
        else {
            return showValue;
        }

    }
    setMultiplier() {
        //if($(this).attr("id")=='pickupcurrpre-range')
        // this.multiplierEIO=parseFloat($("#pickupcurrpre-rangehdn").val().toString());
        //if($(this).attr("id")=='pickupcurr-range')
        // this.multiplierEIR=parseFloat($("#pickupcurr-rangehdn").val().toString());

    }
    GetXml(manufacturerId: number, familyId: string, modelId: number, rating: string, curve: string
        , tmir: string, tmim: string, mim: string, currenttolerance: string, timetolerance: string, timetobreak: string
        , eratingplug: string, eltbool: string, estbool: string, eintbool: string
        , eio: string, eir: string
        , etr: string, eisd: string
        , etsd: string, ei2tcurve: string
        , eli: string, setvalue: number = 0

        , controlchangedId: string = "", idmtl: string
    ) {
        //   alert("hi");
        this._settingService.getXml(manufacturerId, familyId, modelId, rating, curve, tmir, tmim, mim, currenttolerance, timetolerance, timetobreak
            , eratingplug, eltbool, estbool, eintbool
            , eio, eir
            , etr, eisd
            , etsd, ei2tcurve
            , eli, idmtl).subscribe(result => {
                if (result !== undefined && result !== null) {
                    var arr = JSON.parse(result);
                    this.xmlData = arr.xml;
                    this.inputxmlData = arr.xml;
                    this.deviceType = arr.type;
                    this.multiplierIN = arr.inrating;



                    if (this.deviceType == "3") {
                        $("#divelectronic").hide();
                        $("#divmagneticonly").show();
                        $("#divtmagneticonly").hide();

                    }
                    if (this.deviceType == "2") {
                        $("#divelectronic").hide();
                        $("#divmagneticonly").hide();
                        $("#divtmagneticonly").show();


                    }
                    if (this.deviceType == "1") {
                        $("#divelectronic").show();
                        $("#divmagneticonly").hide();
                        $("#divtmagneticonly").hide();

                        if (controlchangedId == "") {


                            // arr.eio ="Type1-12,14,16,18,20,22,23,24,25 (default=25)";
                            // arr.defaulteio ='25';
                            //this.multiplierEIO = arr.defaulteio !="-" ?arr.defaulteio:1;

                            // this.getCalculatedResult(this.getLegendFromValue(this.slidereir,this.slidereiroptions)
                            // ,multiplier);

                            //   this.multiplierEIR = arr.defaulteir!="-" ?arr.defaulteir:1;

                            // arr.etr="-";
                            // arr.eir="Type2-min=90,max=250,step=1 (default=250)";
                            // arr.eli="-";            
                            // arr.defaulteir=250;
                        }


                        var multiplier = 1;
                        var multipliercolumn = $(controlchangedId).attr('multiplier');
                        if (multipliercolumn != undefined) {
                            this.setMultiplier();
                            if (multipliercolumn == "E_Io") {
                                multiplier = this.multiplierEIO;
                            }
                            if (multipliercolumn == "E_Ir") {
                                multiplier = this.multiplierEIR;

                            }
                            if (multipliercolumn == "In") {
                                multiplier = this.multiplierIN;

                            }
                            //multiplier=parseFloat($(controlchangedId).attr('multiplier'));
                        }


                    }
                    this.CableSize['xml'] = this.xmlData;
                    this._CableproCalc.ProtectiveCalculate(this.CableSize).subscribe((result) => {
                        this.getChartLatest(result.curve, result.finaldimsize);

                        this.xmlData = result.xml;
                        this.protectiveXmlInput.chartdata = arr[1];
                        this.protectiveXmlInput.xml = this.xmlData;
                        this.protectiveXmlInput.model = modelId;
                        this.protectiveXmlInput.manufacturer_id = manufacturerId;
                        this.protectiveXmlInput.range_id = familyId;
                        this.protectiveXmlInput.tripunit = curve;
                        this.protectiveXmlInput.rating = rating;
                        //this.UpdateXmlValues(this.protectiveXmlInput);
                    });


                }
                $('#reportDlgVR').modal('hide');
            },
                err => {
                    $('#reportDlgVR').modal('hide');
                });
    }
    GetPolesTableByManufacturerId(manufacturerId: number, familyId: string, modelId: number, rating: number, curve: string) {

    }
    GetCurveByManufacturerId(manufacturerId: number, familyId: string, modelId: number, triggerEvent: boolean, rating: string) {
        this.curveOutput = [];
        this._settingService.getCurveByManufacturer(manufacturerId, familyId, modelId, rating).subscribe(result => {


            if (result !== undefined && result !== null) {
                this.curveOutput = result[0].items;
                if (this.curveOutput.length > 0) {

                    this.GetBreakingByManufacturerId(manufacturerId, familyId, modelId, rating, this.curveOutput[0].cc);
                }

            }
        },
            err => {

            });
    }
    GetModelGroupByManufacturerId(manufacturerId: number, familyId: string) {
        // alert("familyId11"+familyId);
        this.modelOutput = [];
        this._settingService.getModelByManufacturer(manufacturerId, familyId).subscribe(result => {
            if (result !== undefined && result !== null) {
                this.modelOutput = result[0].items;

                if (result[0].items.length > 0) {
                    var a = $('#ddlManufacturer option:selected').val();
                    var manufacturerId = parseInt(a.toString());
                    var modelId = this.modelOutput[0].id;

                    this.ddlmodel = modelId;

                    //  this.curveOutput = [];

                    this.GetRatingByManufacturerId(manufacturerId, familyId, modelId, true);
                    //this.GetBreakingByManufacturerId(manufacturerId,familyId,modelId);

                }

            }
        },
            err => {

            });
    }
    savexml() {
        this.UpdateXmlValues(this.protectiveXmlInput);
        this.CloseCurveData();
    }

    LoadControls(manufacturerId: number, familyId: string, modelId: number, rating: string, curve: string
        , tmir: string, tmim: string, mim: string, currenttolerance: string, timetolerance: string, timetobreak: string
        , eratingplug: string, eltbool: string, estbool: string, eintbool: string
        , eio: string, eir: string
        , etr: string, eisd: string
        , etsd: string, ei2tcurve: string
        , eli: string, idmtl: string, savexml: string = ""
    ) {
        //   alert("hi");
        this._settingService.getXml(manufacturerId, familyId, modelId, rating, curve, tmir, tmim, mim, currenttolerance, timetolerance, timetobreak
            , eratingplug, eltbool, estbool, eintbool
            , eio, eir
            , etr, eisd
            , etsd, ei2tcurve
            , eli, idmtl).subscribe(result => {

                if (result !== undefined && result !== null) {




                    var arr = JSON.parse(result);
                    this.xmlData = arr.xml;
                    this.inputxmlData = arr.xml;
                    if (arr.isrpnumber == "1") {
                        this.rpcaption = "Characteristics";
                    }
                    this.CableSize['xml'] = this.xmlData;
                    this._CableproCalc.ProtectiveCalculate(this.CableSize).subscribe((result) => {
                        this.getChartLatest(result.curve, result.finaldimsize);
                        this.xmlData = result.xml;
                        this.protectiveXmlInput.chartdata = arr[1];
                        this.protectiveXmlInput.xml = this.xmlData;
                        this.protectiveXmlInput.model = modelId;
                        this.protectiveXmlInput.manufacturer_id = manufacturerId;
                        this.protectiveXmlInput.range_id = familyId;
                        this.protectiveXmlInput.tripunit = curve;
                        this.protectiveXmlInput.rating = rating;
                        if (savexml == "1")
                            this.UpdateXmlValues(this.protectiveXmlInput);
                    });
                    var devicearr = this.model.split(' ')[1];
                    if (devicearr != null && devicearr != "" && (devicearr == "MCB" || devicearr == "Fuse")) {
                        $("#divmcbacbonly").show();
                        $("#divelectronic").hide();
                        $("#divmagneticonly").hide();
                        $("#divtmagneticonly").hide();
                    }
                    else {
                        $("#divmcbacbonly").hide();
                        this.deviceType = arr.type;
                        this.multiplierIN = arr.inrating;
                        var setvalue = 1;

                        if (this.deviceType == "3") {
                            $("#divelectronic").hide();
                            $("#divmagneticonly").show();
                            $("#divtmagneticonly").hide();
                            this.getInputValues(arr.mim, '#pickupcurrmgpr', "#pickupcurrmgprspn", "#pickupcurrmgprhdn", "#pickupcurrmgprdiv", setvalue, arr.defaultmim);
                        }
                        if (this.deviceType == "2") {
                            $("#divelectronic").hide();
                            $("#divmagneticonly").hide();
                            $("#divtmagneticonly").show();
                            this.getInputValues(arr.tmir, '#pickupcurrth', "#gridSizeValue", "#pickupcurrthhdn", "#pickupcurrthdiv", setvalue, arr.defaulttmir);
                            this.getInputValues(arr.tmim, '#pickupcurrmg', "#pickupcurrmgspn", "#pickupcurrmgshdn", "#pickupcurrmgsdiv", setvalue, arr.defaulttmim);
                        }
                        if (this.deviceType == "1") {
                            $("#divelectronic").show();
                            $("#divmagneticonly").hide();
                            $("#divtmagneticonly").hide();


                            this.multiplierEIR = arr.defaulteir != "-" ? arr.defaulteir : 1;
                            // alert(arr.rp);
                            if (arr.rp != null && arr.rp != "") {
                                $("#ratingplugdiv").show();

                                $("#ratingplug").val(arr.rp);
                                this.ratingPlugList = arr.rplist;
                                this.rp = arr.rp;
                            }
                            else {
                                $('#ratingplug option:selected').val('');
                                $("#ratingplugdiv").hide();
                            }
                            //    arr.currenttolerance="Type3-E_Ir-min=1,max=10,step=1 (default=1)"
                            //   arr.timetolerance="Type3-E_Ir-min=1,max=10,step=1 (default=2)"
                            //  arr.timetobreak="Type3-E_Ir-min=1,max=10,step=1 (default=3)"

                            //arr.defaultcurrenttolerance=1;
                            //arr.defaulttimetolerance=2;
                            //arr.defaulttimetobreak=3;
                            this.multiplierEIO = arr.defaulteio != "-" ? arr.defaulteio : 1;
                            // alert(arr.ei2tcurve);
                            if (arr.ei2tcurve != "1" && arr.ei2tcurve != "0") {
                                $("#I2tdiv").hide();
                                $('#chcki2curve').prop('checked', false);
                            }
                            else {
                                $("#I2tdiv").show();
                                if (arr.ei2tcurve == "1") {

                                    $('#chcki2curve').prop('checked', true);
                                }
                                else {
                                    $('#chcki2curve').prop('checked', false);

                                }
                            }

                            this.getInputValues(arr.currenttolerance, '#current-tolerance-range', "#current-tolerance-rangespn", "#current-tolerance-rangehdn", "#current-tolerance-rangediv", setvalue, arr.defaultcurrenttolerance);
                            this.getInputValues(arr.timetolerance, '#time-tolerance-range', "#time-tolerance-rangespn", "#time-tolerance-rangehdn", "#time-tolerance-rangediv", setvalue, arr.defaulttimetolerance);
                            this.getInputValues(arr.timetobreak, '#time-tobreak-range', "#time-tobreak-rangespn", "#time-tobreak-rangehdn", "#time-tobreak-rangediv", setvalue, arr.defaulttimetobreak);
                            this.getInputValues(arr.eio, '#pickupcurrpre-range', "#pickupcurrpre-rangespn", "#pickupcurrpre-rangehdn", "#pickupcurrpre-rangediv", setvalue, arr.defaulteio);
                            this.getInputValues(arr.eir, '#pickupcurr-range', "#pickupcurr-rangespn", "#pickupcurr-rangehdn", "#pickupcurr-rangediv", setvalue, arr.defaulteir);
                            this.getInputValues(arr.etr, '#timedelay-range', "#timedelay-rangespn", "#timedelay-rangehdn", "#timedelay-rangediv", setvalue, arr.defaultetr);
                            this.getInputValues(arr.eisd, '#pickupshort-range', "#pickupshort-rangespn", "#pickupshort-rangehdn", "#pickupshort-rangediv", setvalue, arr.defaulteisd);
                            this.getInputValues(arr.etsd, '#timedelayshort-range', "#timedelayshort-rangespn", "#timedelayshort-rangehdn", "#timedelayshort-rangediv", setvalue, arr.defaultetsd);
                            this.getInputValues(arr.eli, '#pickupcurrinst-range', "#pickupcurrinst-rangespn", "#pickupcurrinst-rangehdn", "#pickupcurrinst-rangediv", setvalue, arr.defaulteli);
                            this.getInputValues(arr.idmtl, '#idmtl-settings', "#idmtl-settingsspn", "#idmtl-settingshdn", "#idmtl-settingsdiv", setvalue, arr.defaultidmtl);
                            // alert(arr.ei2tcurve);
                            // alert(arr.eisd);

                            if (arr.eltboolproperty == "show") {
                                //
                                $('#chcklongtime').prop('checked', true);
                                $("#divlongtimeprot").show();
                                $("#divlongtimeprotchk").show();
                                $("#divlongtimeprotchk :input").removeAttr('disabled');
                            }
                            else if (arr.eltboolproperty == "hide") {
                                //$("#divlongtimeprot").hide();
                                //$("#chcklongtime").removeAttr("checked");
                                $('#chcklongtime').prop('checked', false);
                                if (arr.isrpnumber == "1") {
                                    $('#chcklongtime').prop('checked', true);
                                    $("#timedelay-rangediv").hide();
                                }
                                $("#divlongtimeprotchk").hide();
                            }
                            else if (arr.eltboolproperty == "disabled" || arr.eltboolproperty == null) {
                                $('#chcklongtime').prop('checked', true);
                                $("#divlongtimeprot").show();
                                $("#divlongtimeprotchk").show();
                                $("#divlongtimeprotchk :input").attr('disabled', 'true');
                            }

                            if ($('#ratingplug option:selected').val() == "OFF") {
                                $("#pickupcurr-rangediv").hide();
                                $("#timedelay-rangediv").hide();
                            }
                            if (arr.estboolproperty == "show") {
                                $("#divshorttime").show();
                                $("#divshorttimechk").show();
                                $("#divshorttimechk :input").removeAttr('disabled');
                                $('#chckshorttime').prop('checked', true);

                            }
                            else if (arr.estboolproperty == "hide") {
                                $("#divshorttime").hide();
                                $("#divshorttimechk").hide();

                                $('#chckshorttime').prop('checked', false);
                                if (arr.isrpnumber == "1") {
                                    $('#chckshorttime').prop('checked', true);
                                }


                            }
                            else if (arr.estboolproperty == "disabled" || arr.estboolproperty == null) {
                                $("#divshorttime").show();
                                $("#divshorttimechk").show();
                                $("#divshorttimechk :input").attr('disabled', 'true');
                                $('#chckshorttime').prop('checked', true);

                            }
                            if (arr.eintboolproperty == "show") {
                                $("#divinstant").show();
                                $("#divinstantchk").show();
                                $('#chckInstant').prop('checked', true);
                                $("#divinstantchk :input").removeAttr('disabled');
                            }
                            else if (arr.eintboolproperty == "hide") {
                                $("#divinstant").hide();
                                $('#chckInstant').prop('checked', false);
                                if (arr.isrpnumber == "1") {
                                    $('#chckInstant').prop('checked', true);
                                }

                                $("#divinstantchk").hide();
                            }
                            else if (arr.eintboolproperty == "disabled" || arr.eintboolproperty == null) {
                                $("#divinstant").show();
                                $('#chckInstant').prop('checked', true);
                                $("#divinstantchk").show();
                                $("#divinstantchk :input").attr('disabled', 'true');
                            }
                        }
                    }




                }
                $('#reportDlgVR').modal('hide');
            },
                err => {
                    $('#reportDlgVR').modal('hide');
                });
    }
    GetModelGroupByManufacturerIdnew(manufacturerId: number, familyId: string, filterBreaking: number) {

        if ($('#chkexportlimited:checked').length > 0) {

        }
        else {
            filterBreaking = 0;
        }
        this.deviceOutput = new DeviceOutput();
        this._settingService.getModelByManufacturernew(manufacturerId, familyId, filterBreaking).subscribe(result => {
            if (result !== undefined && result.one !== null) {
                this.modelOutput = result.one;


            }
            if (result !== undefined && result.two !== null) {
                this.mcbOutput = result.two;

            }
            else {
                this.mcbOutput = [];
            }
            if (result !== undefined && result.three !== null) {
                this.curveOutput = result.three;

            }
            else {
                this.curveOutput = [];
            }
            if (this.loadProtectiveXmlInput != null && this.loadProtectiveXmlInput.tripunit != null && this.loadProtectiveXmlInput.tripunit != "" && this.loadProtectiveXmlInput.tripunit != undefined) {
                this.defaultCurveId = this.loadProtectiveXmlInput.tripunit;

            }
            if (this.loadProtectiveXmlInput != null && this.loadProtectiveXmlInput.model != null && this.loadProtectiveXmlInput.model != 0 && this.loadProtectiveXmlInput.model != undefined) {
                this.defaultModelId = this.loadProtectiveXmlInput.model;

                this.GetRatingByManufacturerId(manufacturerId, familyId, this.defaultModelId, true);

            } else {

                this.defaultModelId = this.modelOutput[0].id;
                this.GetRatingByManufacturerId(manufacturerId, familyId, this.modelOutput[0].id, true);
            }


            if (this.loadProtectiveXmlInput != null && this.loadProtectiveXmlInput.xml != null && this.loadProtectiveXmlInput.xml != "" && this.loadProtectiveXmlInput.xml != undefined) {
                this.xmlData = this.loadProtectiveXmlInput.xml;

            }



        },
            err => {

            });
    }
    GetManufacturer() {

        this._settingService.getManufacturer().subscribe(result => {

            if (result !== undefined && result !== null) {
                this.manufacturerOutput = result[0].items;
                if (this.manufacturerOutput.length > 0) {
                    var manId = 104;
                    if (this.loadProtectiveXmlInput != null && this.loadProtectiveXmlInput.manufacturer_id > 0) {
                        manId = this.loadProtectiveXmlInput.manufacturer_id;
                        this.defaultManId = manId;


                    }
                    this.GetFamilyGroupByManufacturerId(manId, true);

                }

            }
        }, error => {
        });
    }

    DeleteExcelFile(a: string, fileType: string) {
        //        var a = $('#ddlMan option:selected').val().toString();
        this._settingService.getDeleteFile(a, fileType).subscribe(result => {

            // if (result == true) {
            alert("File Deleted Successfully");
            this.GetUploadedFiles();
            this.GetManufacturer();

            //} 
        }, error => {
        });
    }

    GetUploadedFiles() {

        this._settingService.getUploadedFiles().subscribe(result => {

            if (result !== undefined && result !== null) {
                this.filesOutput = result[0].items;

            }
        }, error => {
        });
    }
    GetProtectiveData() {

        this._settingService.getProtectiveData().subscribe(result => {
            if (result !== undefined && result !== null) {
                this.loadProtectiveXmlInput = result;
                if (this.loadProtectiveXmlInput.chartdata != null) {
                    this.chartData = this.loadProtectiveXmlInput.chartdata.replace(/\\/g, '');
                    this.chartData = this.chartData.replace('"[', '[');
                    this.chartData = this.chartData.replace(']"', ']');
                    var a = JSON.parse(this.chartData);
                    this.chartArray = a;
                    this.getChart(this.chartArray);
                }
            }
            this.GetManufacturer();
        }, error => {
        });
    }
    GetDefaultSetting() {

        this._settingService.getSettings().subscribe(result => {

            if (result.id !== undefined && result.id !== null) {
                this.settingOutput = result;
                this.setOutput();
                this.settingInput.manId = 100;

                this.cableSizeValue = this.cableSizeStandards[this.settingOutput.standards_CableSizing].toString();
                this.standard = this.settingOutput.standards_CableSizing;
            } else {
                this.setDefaultOutput();
            }
            this.getCurrentLoginInformations();
        }, error => {
        });
    }

    setOutput(): void {

        this.settingInput.standards_CableSizing = this.settingOutput.standards_CableSizing;
        this.settingInput.voltageDrop_NeutralCondTempMode = this.settingOutput.voltageDrop_NeutralCondTempMode;
        this.settingInput.load_UnitValue = 0;
        this.settingInput.deafaultVoltages_ThreePhaseAcvoltage = this.settingOutput.deafaultVoltages_ThreePhaseAcvoltage;
        this.settingInput.deafaultVoltages_OnePhaseAVoltage = this.settingOutput.deafaultVoltages_OnePhaseAVoltage;
        this.settingInput.deafaultVoltages_DcVoltage = this.settingOutput.deafaultVoltages_DcVoltage;
        this.settingInput.companyLogo_ShowLogo = this.settingOutput.companyLogo_ShowLogo;
        if (this.settingOutput.company_Logo_LogoPresignedUrl == null || this.settingOutput.company_Logo_LogoPresignedUrl === undefined || this.settingOutput.company_Logo_LogoPresignedUrl === '') {

            this.settingInput.company_Logo_LogoPresignedUrl = this.defaultLogoImagePath;
            this.deleteVisible = false;
        } else {

            this.settingInput.company_Logo_LogoPresignedUrl = this.settingOutput.company_Logo_LogoPresignedUrl;
            this.deleteVisible = true;
        }

        this.settingInput.short_Circuit_VoltageFactor_C = this.settingOutput.short_Circuit_VoltageFactor_C;
        this.settingInput.standards_AllowPartSizeNeutral = this.settingOutput.standards_AllowPartSizeNeutral;
        this.settingInput.customeReportFields_ShowCustomField = this.settingOutput.customeReportFields_ShowCustomField;
        this.settingInput.id = this.settingOutput.id;
        if (this.settingOutput.customFields != undefined) {
            for (var j = 0; j < this.settingOutput.customFields.length; j++) {
                if (j == 0)
                    this.settingOutput.customFields[j].isSelected = true;
                else
                    this.settingOutput.customFields[j].isSelected = false;
            }
        }

        this.settingInput.customFields = this.settingOutput.customFields;
        this.settingInput.companyLogo_FileName = this.settingOutput.companyLogo_FileName;
        this.settingInput.companyLogo_LogoUrl = this.settingOutput.companyLogo_LogoUrl;
        $('#drpNeutralCond').val(this.settingInput.voltageDrop_NeutralCondTempMode);
        $('#drpNeutralCond').trigger('change');
        // $('#drpunit').val(this.settingInput.load_UnitValue);
        $('#drpunit').trigger('change');
    }

    setDefaultOutput(): void {
        this.settingInput.standards_CableSizing = 0;
        this.settingInput.manId = 100;
        this.settingInput.standards_AllowPartSizeNeutral = true;
        this.settingInput.customeReportFields_ShowCustomField = false;
        this.settingInput.companyLogo_ShowLogo = false;
        this.settingInput.companyLogo_LogoUrl = null;
        this.settingInput.deafaultVoltages_ThreePhaseAcvoltage = 400;
        this.settingInput.deafaultVoltages_OnePhaseAVoltage = 230;
        this.settingInput.deafaultVoltages_DcVoltage = 1500;
        this.settingInput.voltageDrop_NeutralCondTempMode = 0;
        this.settingInput.short_Circuit_VoltageFactor_C = 1.1;
        this.settingInput.load_UnitValue = 0;
        $('#drpNeutralCond').val(0);
        $('#drpNeutralCond').trigger('change');
        $('#drpunit').val(0);
        $('#drpunit').trigger('change');
        this.settingInput.company_Logo_LogoPresignedUrl = this.defaultLogoImagePath;
        this.deleteVisible = false;
    }

    public setDeleteIndex(i): void {
        this.indextoDelete = i;
        for (var j = 0; j < this.settingInput.customFields.length; j++) {
            if (j == i)
                this.settingInput.customFields[j].isSelected = true;
            else
                this.settingInput.customFields[j].isSelected = false;
        }
    }

    SetExcelFileUpload(event: any, type: string) {

        let url_ = this.baseUrl + '/api/services/app/Setting/UploadExcelFile?';
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            let file: any = fileList[0];
            if (!this.validateExcelFile(file.name)) {
                return false;
            }
            else {
                this.showLoader();
                let formData: FormData = new FormData();
                var d = new Date(file.lastModifiedDate);
                var dformat = [
                    d.getDate(),
                    d.getMonth() + 1,
                    d.getFullYear()].join('-') + ' ' +
                    [d.getHours() + "h",
                    d.getMinutes() + "m",
                    d.getSeconds() + "s"].join(' ');
                // alert(dformat);
                if (url_ !== undefined)
                    url_ += "lastmodifiedtime=" + encodeURIComponent("" + dformat);
                // var a = $('#ddlMan option:selected').val();

                if (url_ !== undefined)
                    url_ += "&&Man=" + encodeURIComponent("" + '');

                if (url_ !== undefined)
                    url_ += "&&type=" + encodeURIComponent(type);

                formData.append('file', file, file.name);
                // formData.append('lastmodifiedtime', file, file.lastModifiedDate.toDateString());
                let headers = new Headers();
                headers.append('Content-Type', 'multipart/form-data');
                headers.append('Accept', 'application/json');
                this.getUploadFileResponse(url_, formData).subscribe(result => {

                    // alert(result.result);
                    //    var a =JSON.parse(result) ;
                    //   alert( a.result);
                    if (result.result == "true") {
                        this.GetUploadedFiles();
                        this.GetManufacturer();
                        $("#fileuploadexcel").val("");
                        this.hideLoader();
                        alert("Data Transfer successfully");
                    }
                    else if (result.result == "fileexist") {
                        this.hideLoader();

                        alert("The file already exist on the server");
                        $("#fileuploadexcel").val("");



                    }
                    else if (result.result == "filenotexist") {
                        this.hideLoader();
                        $("#fileuploadexcel2").val("");

                        alert("The file of the uploaded manfacturer not exist on the server");



                    }
                    else if (result.result == "incorrect") {
                        this.hideLoader();
                        $("#fileuploadexcel2").val("");
                        $("#fileuploadexcel").val("");

                        alert("Please match the file name format: [manufacturer] [device type] [(version number)]_...");



                    }
                    if (type == "modify") {
                        $("#fileuploadexcel2").val("");
                    }
                    // if (this.settingOutput !== undefined) {
                    //     this.btnuploadDisabled = true;
                    //     this.settingInput.companyLogo_LogoUrl = this.settingOutput['result'].companyLogo_LogoUrl;
                    //     this.settingInput.company_Logo_LogoPresignedUrl = this.settingOutput['result'].company_Logo_LogoPresignedUrl;
                    //     this.settingInput.companyLogo_FileName = this.settingOutput['result'].companyLogo_FileName;
                    //     this.deleteVisible = true;
                    //     this.btnuploadDisabled = false;

                    //     this._settingService.updateSettings().subscribe(result => {
                    //     }, error => {
                    //     });
                    // }
                }, (error) => {
                    alert("Some error occurred. Please make sure there is correct data in the uploaded excel file");
                    $("#fileuploadexcel").val("");

                    this.hideLoader();

                    this.btnuploadDisabled = false;
                    this.isInvalidExcelFileType = true;
                });
            }
        }
    }

    getUploadFileResponse(url_: string, formData: FormData): Observable<any> {
        return this.http.post<any>(url_, formData);
    }

    ExportLimitedChange(e) {

        if ($('#chkexportlimited:checked').length > 0) {
            this.showFilter = true;
        }
        else {
            this.showFilter = false;

        }
        var a = $('#ddlManufacturer option:selected').val();
        var manufacturerId = parseInt(a.toString());
        //         this.GetModelGroupByManufacturerIdnew(manufacturerId,this.model,this.FilterBreaking);

        var familyId = this.model.toString();
        //   //var c = $('#ddlModel option:selected').val();
        //   var  modelId=this.ddlmodel;
        var c = $('#ddlModel option:selected').val();
        var modelId = parseInt(c.toString());
        var d = $('#ddlrating option:selected').val();
        var rating = parseFloat(d.toString());
        var ee = $('#ddlcurve option:selected').val();
        var curve = (ee.toString());

        this.GetBreakingByManufacturerId(manufacturerId, familyId, modelId, d.toString(), curve);


    }

    FilterChange(e) {
        var a = $('#ddlManufacturer option:selected').val();
        var manufacturerId = parseInt(a.toString());
        // this.setFilterBreaking();
        this.GetModelGroupByManufacturerIdnew(manufacturerId, this.model, this.FilterBreaking);

    }
    CheckFormValid(): boolean {

        if ($('#ddlManufacturer').val() == null || $('#ddlManufacturer').val() === undefined || $('#ddlManufacturer').val() === 0) {
            this.IsFormvalid = false;
            return false;
        }
        if ($('#ddlFamily').val() == null || $('#ddlFamily').val() === undefined || $('#ddlFamily').val() === 0) {
            this.IsFormvalid = false;
            return false;
        }

        if ($('#ddlModel').val() == null || $('#ddlModel').val() === undefined || $('#ddlModel').val() === 0) {
            this.IsFormvalid = false;
            return false;
        }
        if ($('#ddlrating').val() == null || $('#ddlrating').val() === undefined || $('#ddlrating').val() === 0) {
            this.IsFormvalid = false;
            return false;
        }
        if ($('#ddlcurve').val() == null || $('#ddlcurve').val() === undefined || $('#ddlcurve').val() === 0) {
            this.IsFormvalid = false;
            return false;
        }
        return true;
    }
    SetLogoImage(event: any) {
        let url_ = this.baseUrl + '/api/services/app/Setting/CreateFile';
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            let file: File = fileList[0];
            if (!this.validateFile(file.name)) {
                return false;
            }
            else {
                let resizedFile: File;
                var utils = new ImageUtils();
                utils.resizeImage(file, 600, 600).then(blob => {
                    //You can upload the resized image: doUpload(blob)
                    resizedFile = utils.blobToFile(blob, file.name);
                    let formData: FormData = new FormData();
                    formData.append('file', resizedFile, file.name);
                    // formData.append('file', file, file.name);
                    let headers = new Headers();
                    headers.append('Content-Type', 'multipart/form-data');
                    headers.append('Accept', 'application/json');
                    this.getUploadResponse(url_, formData).subscribe(result => {
                        this.settingOutput = result;
                        if (this.settingOutput !== undefined) {
                            this.btnuploadDisabled = true;
                            this.settingInput.companyLogo_LogoUrl = this.settingOutput['result'].companyLogo_LogoUrl;
                            this.settingInput.company_Logo_LogoPresignedUrl = this.settingOutput['result'].company_Logo_LogoPresignedUrl;
                            this.settingInput.companyLogo_FileName = this.settingOutput['result'].companyLogo_FileName;
                            this.deleteVisible = true;
                            this.btnuploadDisabled = false;
                            this._settingService.updateSettings().subscribe(result => {
                            }, error => {
                            });
                        }
                    }, (error) => {
                        this.btnuploadDisabled = false;
                        this.isInvalidFileType = true;
                    });
                }, err => {
                    console.error("Photo error", err);
                });
            }
        }
    }

    getUploadResponse(url_: string, formData: FormData): Observable<SettingOutput> {
        return this.http.post<SettingOutput>(url_, formData);
    }

    cancelRedirect() {
        this._router.navigate(['/app/main/projects/']);
    }
    showLoader(): void {
        this.loaderService.show();
    }
    hideLoader(): void {
        this.loaderService.hide();
    }
    removeLogo(): void {
        this.settingInput.company_Logo_LogoPresignedUrl = '';
        this.settingInput.companyLogo_LogoUrl = '';
        this.settingInput.companyLogo_FileName = '';
        this.settingInput.company_Logo_LogoPresignedUrl = this.defaultLogoImagePath;
        this.deleteVisible = false;
    }


    validateExcelFile(name: string): boolean {
        var ext = name.substring(name.lastIndexOf('.') + 1);
        if (ext.toLowerCase() == 'xlsx') {
            this.isInvalidExcelFileType = false;
            return true;
        }
        else {
            this.isInvalidExcelFileType = true;
            return false;
        }
    }
    validateFile(name: string): boolean {
        var ext = name.substring(name.lastIndexOf('.') + 1);
        if (ext.toLowerCase() == 'gif' || ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'png'
            || ext.toLowerCase() == 'tiff' || ext.toLowerCase() == 'ico' || ext.toLowerCase() == 'bmp') {
            this.isInvalidFileType = false;
            return true;
        }
        else {
            this.isInvalidFileType = true;
            return false;
        }
    }

    openfileControl(): void {
        $('#fileupload').click();
    }
    openexcelfileControl(): void {
        $('#fileuploadexcel').click();
    }
    openexcelfileControlUpdate(): void {
        $('#fileuploadexcel2').click();
    }

    setPlansUrl(): void {
        if (this.settingInput.standards_CableSizing === 0 || this.settingInput.standards_CableSizing === 1)
            this._router.navigate(['/app/main/projects/', 'subscriptionplans']);
        else if (this.settingInput.standards_CableSizing === 2)
            this._router.navigate(['/app/main/projects/', 'bscalcsubscriptionplans']);
    }
    CommonValidationFn(phaseVoltage: number, event: any, numberofplaces: number) {
        this.Allowdecimalwithplaces(event, numberofplaces);

        if (parseFloat(event.target.value) > parseFloat(event.target.max)) {
            event.target.value = event.target.max;
        }
        if (parseFloat(event.target.value) < parseFloat(event.target.min) || event.target.value === '') {
            event.target.value = event.target.min;
        }

        if (phaseVoltage === 1)
            this.settingInput.deafaultVoltages_OnePhaseAVoltage = event.target.value;
        else if (phaseVoltage === 3)
            this.settingInput.deafaultVoltages_ThreePhaseAcvoltage = event.target.value;
        else if (phaseVoltage === 4)
            this.settingInput.short_Circuit_VoltageFactor_C = event.target.value;
        else if (phaseVoltage === 0)
            this.settingInput.deafaultVoltages_DcVoltage = event.target.value;
        //this.getAllControlsValue();
    }

    onCopyCalculation(frm: NgForm) {
        this.frmSubmit = true;
        if (frm.form.valid) {
            this.copyCalcLoader = true;
            this.showLoader();
            this._settingService.copyUtility(this.copyUtilityObj).subscribe((res) => {
                this.hideLoader();
                this.copyCalcLoader = false;
                if (res && res.isSuccess) {
                    let message = res.message;
                    if (res.calculationUrl) {
                        message = res.message + '<br><a href="' + res.calculationUrl + '" target="_blank">Please click here to open calculation</a>';
                    }
                    this._messageService.success(message, 'Success', true);
                    this.copyUtilityObj = {
                        CalculationUrl: '',
                        UserName: '',
                        ProjectName: '',
                    }
                    this.frmSubmit = false;
                }
                else
                    this._messageService.error(res.message);
            }, error => {
                this.copyCalcLoader = false;
                this.hideLoader();
            });
        }
    }

    downloadXml() {
        if (!this.copyUtilityObj.CalculationUrl) {
            this._messageService.error("Please enter calculation URL to download input XML.");
        }
        else {
            this.showLoader();
            this.downloadInputXmlLoader = true;
            this._settingService.downloadInputXml(this.copyUtilityObj).subscribe((res) => {
                this.hideLoader();
                this.downloadInputXmlLoader = false;
                if (res && res.isSuccess) {
                    if (res.message) {
                        this.textToFileDownload(res.calculationName + "_XML.txt", res.message);
                        this.copyUtilityObj = {
                            CalculationUrl: '',
                            UserName: '',
                            ProjectName: '',
                        }
                    }
                }
                else
                    this._messageService.error(res.message);
            }, error => {
                this.hideLoader();
                this.downloadInputXmlLoader = false;
            });
        }
    }

    private dynamiclement = {
        element: {
            dynamicDownload: null as HTMLElement
        }
    }

    private textToFileDownload(fileName: string, text: string) {
        if (!this.dynamiclement.element.dynamicDownload) {
            this.dynamiclement.element.dynamicDownload = document.createElement('a');
        }
        const element = this.dynamiclement.element.dynamicDownload;
        const fileType = 'text/plain';
        element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(text)}`);
        element.setAttribute('download', fileName);

        var event = new MouseEvent("click");
        element.dispatchEvent(event);
    }

    public projectOutput: ProjectOutput[] = [];
    getproject() {
        this._projectService.getProjects().subscribe(result => {
            if (result.items.length > 0) {
                this.projectOutput = result.items.filter(x => x.isArchive == false && x.isUserAsATeamMember == false);
            }
            else {
                this.projectOutput = [];
            }
        });
    }

    onProjectCopy(frm: NgForm) {
        this.frmSubmitSampleCalc = true;
        if (frm.form.valid) {
            this._messageService.confirm("Do you want to copy project?", "Please confirm", (result) => {
                if (result) {
                    this.SampleCalcLoader = true;
                    this.sampleCalcObj.CableSizeStandard = this.standard;
                    this.showLoader();
                    this._projectService.projectCopyUtility(this.sampleCalcObj).subscribe((res) => {
                        this.hideLoader();
                        this.SampleCalcLoader = false;
                        if (res && res == "1") {

                            this._messageService.success("Project copied successfully.", 'Success', true);
                            this.sampleCalcObj = {
                                TargetUserType: '',
                                UserNames: '',
                                ProjectId: '',
                                ProjectType: '',
                            }
                            this.frmSubmitSampleCalc = false;
                        }
                        else if (res == "0")
                            this._messageService.error("Some error occured contact with admin");
                        else
                            this._messageService.error(res);
                    }, error => {
                        this.SampleCalcLoader = false;
                        this.hideLoader();
                    });
                }
            });
        }
    }

    ManageSub() {
        this.ManageSubscription(this.user, this.loaderService, this._subscriptionService, "");
    }

    logoutuser(): void {
        if (localStorage.getItem("ReportSettingEmails"))
            localStorage.removeItem("ReportSettingEmails");

        this._utilsService.deleteCookie(AppConsts.productLife.ssoTokenKey);
        this._authService.logout();
    }
}
