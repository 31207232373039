import { MaxDemandData } from "@shared/service-proxies/service-proxy-calc";

export class NetworkCalcInputXML {
    Loads: NetworkLoad[];
    Switchboards: NetworkSwitchBoard[];
    CapacitorBanks: NetworkCapacitorBank[];
    SupplySettings: SupplySettings;
    NetworkSettings: NetworkSettings;
    NetworkHVRelay: NetworkHVRelay[];
    PowerFactorCorrections: PowerFactorCorrection[];
    matrix: any;
    CableSizeCalcInputs: string;
    isSingleLineDiagram: number;
    isDXF: number;
    treeItems: string;
    browserName: string;
    networkTree: any;
    MaxDemandDataArray: MaxDemandData[];
    calculationId: number;
    UserName: string;
}

export class MaxDemandCalcInput {
    CableSizeCalcInputId: number;
    Node_Id: number;
    Node_Prefix: string;
    Node_Name: string;
    PhaseR: string;
    PhaseW: string;
    PhaseB: string;
    PhaseImbalance: string;
    MaximumDemand: string;
    LoadItemsData = [];
}

export class CableSizeCalcInput {
    CableSizeCalcInputId: number;
    Node_Id: string;
    Node_Prefix: string;
    Node_Name: string;
    CableSizeId: string;
    DeviceName: string;
    CableSizeCalcInputXML: {};

}

export class NetworkLoad {

    Load_Id: number;
    Load_Matrix_Node: string;
    Load_Name: string;
    Load_Phases: number;// 1Phase| 3Phase
    Load_Phase_1_Current: number;
    Load_Phase_1_Phase: number;//L1|L2|L3
    Load_Phase_1_Powerfactor: number;
    Load_Phase_1_Harmonics: number;

    Load_Phase_3_Currents: number;//Bal|UnBal
    Load_Phase_3_Phase_Current: number;
    Load_Phase_3_Powerfactor: number;
    Load_Phase_3_Harmonics_Balanced: number;

    Load_Phase_3_Phase_Current_L1: number;
    Load_Phase_3_Phase_Current_L2: number;
    Load_Phase_3_Phase_Current_L3: number;

    Load_Phase_3_Powerfactor_L1: number;
    Load_Phase_3_Powerfactor_L2: number;
    Load_Phase_3_Powerfactor_L3: number;

    Load_Phase_3_Harmonics_UnBalanced: number;


}

export class NetworkSwitchBoard {

    SwitchBoard_Id: number;
    SwitchBoard_Matrix_Node: string;
    SwitchBoard_Name: string;
    SwitchBoard_Phases: number;
    SwitchBoard_Allowable_Voltage_Drop: number;
    SwitchBoard_Total_Maximum_Demand: number;

    SwitchBoard_Phase_1_Current: number;
    SwitchBoard_Phase_1_Phase: number;
    SwitchBoard_Phase_1_Power_factor: number;

    SwitchBoard_Phase_3_Currents: number;
    SwitchBoard_Phase_3_Phase_Current: number;
    SwitchBoard_Phase_3_Phase_Power_factor: number;

    SwitchBoard_Phase_3_Phase_Current_L1: number;
    SwitchBoard_Phase_3_Phase_Current_L2: number;
    SwitchBoard_Phase_3_Phase_Current_L3: number;

    SwitchBoard_Phase_3_Phase_Power_factor_L1: number;
    SwitchBoard_Phase_3_Phase_Power_factor_L2: number;
    SwitchBoard_Phase_3_Phase_Power_factor_L3: number;

    // SwitchBoard_Phase_3_Harmonics:number;



}

export class NetworkCapacitorBank {

    NetworkCapacitorBank_Id: number;
    NetworkCapacitorBank_Node_Id: string;
    NetworkCapacitorBank_Matrix_Node: string;
    NetworkCapacitorBank_Name: string;
    NetworkCapacitorBank_Power_Factor_After_Correction: string;
    NetworkCapacitorBank_Power_Factor_Before_Correction: string;
    NetworkCapacitorBank_Rating: number;
    NetworkCapacitorBank_Required_Compensation: number;

}

export class SupplySettings {
    SupplyType: number;
    SupplyTypeName: string;

    Networkfeeder_Phases: number;
    Networkfeeder_Fault_Level: number;

    ExistingSwitchBoard_Phases: number;
    ExistingSwitchBoard_Fault_Level: number;

    Transformer_Phases: number;
    Transformer_Primary_Voltage: number;
    Transformer_Primary_Fault_Level: number;
    Transformer_HV_Relay_Device: number;
    Transformer_Auto_Size_Transformer: number;
    Transformer_No_Of_Parallel_Transformers: number;
    Transformer_Custom_Transformer_Impedence: number;
    Transformer_Custom_Rating: number;
    Transformer_Impedence_Voltage: number;
    Transformer_XR_Ratio: number;
    Transformer_Impedence_Correction_Factor: number;
    Transformer_Calculation_Method: number;
    Transformer_Load_Losses: number;

    Generator_Auto_Size_Generator: number;
    Generator_Phases: number;
    Generator_Rated_Voltage: number;
    Generator_No_Of_Parallel_Generators: number;
    Generator_Customer_Rating: number;
    Generator_Custom_Generator_Impedence: number;
    Generator_Subtransient_Reactance: number;
    Generator_XDR_Ratio: number;
    Generator_Impedence_Correction_Factor: number;
    Generator_Power_Factor_Of_Generators: number;
    Generator_Voltage_Regulation: number;

    Power_Station_Generator_Rated_Voltage: number;
    Power_Station_HV_Relay_Device: number;
    Power_Station_Auto_Size_Power_Station: number;
    Power_Station_No_Of_Parallel_Units: number;
    Power_Station_Generator_Custom_Rating: number;
    Power_Station_Transformer_Custom_Rating: number;
    Power_Station_Custom_Generator_Impedence: number;
    Power_Station_Subtransient_Reactance: number;
    Power_Station_XDR_Ratio: number;
    Power_Station_Custom_Transformer_Impedence: number;
    Power_Station_Impedence_Voltage: number;
    Power_Station_XR_Ratio: number;
    Power_Station_Impedence_Correction_Factor: number;
    Power_Station_Power_Factor_Of_Generator: number;
    Power_Station_Generator_Voltage_Regulation: number;
    Power_Station_Calc_Transformer_XandR: number;
    Power_Station_Transformer_Load_Losses: number;
    HVFuse: boolean;
    HvFuseXml: string;
    HvFuseTrippingXml: string;
}

export class NetworkSettings {

    SupplyVoltage: string;
    Frequency: string;
    VoltageTolerance: string;
    AllowableVoltageDrop_Network: string;
    AllowableVoltageDrop_MainsCable: string;
    CableCalcStandard: string;
    TransformerTypicalDataSource: string;
    CableCalcProtectiveDeviceSource: string;
    ProtectionCoordinationStandard: string;
    CategorySource: string;
    CalculationMethod: string;
    IsArcFlash: boolean;
    ppeCategoriesTable: any;
    ppeCategoriesTableHeaders: any;
}

export class NetworkHVRelay {
    HVRelay_Id: number;
    HVRelay_Matrix_Node: string;
    HVRelay_Curve_Equation: string;
    HVRelay_Curve_Type: number;
    HVRelay_Pick_Up_Current: number;
    HVRelay_TMS_TDS: number;
    HVRelay_Max_Disconnection_Time: number;
    HVRelay_Tipping_Current_Max_Disconnection_Time: number;
    HVRelay_Tolerance: number;
}

export class PowerFactorCorrection {
    Node_Id: string;
    Node_Prefix: string;
    Node_Name: string;
    PFC_Id: string;
    PFC_Target_Power_Factor: string;
    PFC_Auto_Size_Capacitor_Bank: string;
    PFC_Capacitor_Bank_To_Install: string;
    PFC_Capacitor_Bank_Custom_Rating: number;
    PFC_Economic_Calculation: string;
    PFC_Installation_Cost: number;
    PFC_Demand_Charge: number;
    PFC_Utility_Factor: number;
    PFC_Benefit_Time_Period: number;
    PFC_Interest_Rate: number;

}

export class NetworkCableSize {
    NetworkCableSize_Load_Current: string;
    NetworkCableSize_Load_DeratingFactor: string;
    NetworkCableSize_Load_PowerFactor: string;
    NetworkCableSize_Supply_Phases: string;
    NetworkCableSize_Supply_VoltageDropRisePercent: string;
    NetworkCableSize_Supply_Voltage: string;
    NetworkCableSize_Supply_LengthOfRun: string;
    NetworkCableSize_Supply_FaultLevel: string;
    NetworkCableSize_Insulation_Insulation: string;
    NetworkCableSize_Insulation_Conductor: string;
    NetworkCableSize_Insulation_Cores: string;
    NetworkCableSize_Installation: string;
    NetworkCableSize_Protection_Device: string;
    NetworkCableSize_Protection_FaultTime: string;

}


export class Model {
    InsulationId: number;
    ConductorId: number;
    EarthConductorId: number;
    CoreId: number;
    ArmourId: number;
    PhaseId: number;
    ActiveId: number;
    NeutralId: number;
    EarthId: string;
    MethodIndex: number;
    InstallTableId: number;
    WindSpeedId: number;
    ConduitSizeId: number;
}
export class Table22Model {
    Arrangement: number;
    AddType: number;
    NoOfCircuits: number;
    drf: number;
}
export class Table271Model {
    AbientTemp: number;
    AmbientTemp: number;
    ConductorTemp: number;
    UseStandard: boolean;
    drf: number;
    Insulation: number;
}
export class Table25Model {
    CableType: number;
    NoOfCircuits: number;
    TouchingType: number;
    InstallType: number;
    Distance: number;
    drf: number;
    Arrangement: number;
}
export class Table28Model {
    UseStandard: boolean;
    Depth: number;
    ConductorSize: number;
    Core: number;
    Buried: number;
    drf: number;
}
export class Table29Model {
    UseStandard: boolean;
    Resitivity: number;
    Installation: number;
    drf: number;
    Buried: number;

}
export class Table23Model {
    CableType: number;
    Installation: number;
    Row: number;
    Cables: number;
    drf: number;
}
export class Table23InstallationModel {
    name: string;
    value: number;
    img: string;
    desc2: string;
    desc: string;
}

export class ErrorWarningModel {
    ErrorCode: string;
    IsError: string;
    Message: string;
    Type: string;
    Category?: string;
}

export class ProtectionConfigurationModel {
    EarthingSystem: string;
    MaxDisconnectionTime: string;
    CustomTime: string;
    MaxAllowedEFLImpedance: string;
}
export class TreeListItem {
    text: String;
    value: number;
    level: number;
    children: any;
    collapsed: boolean;
}
