// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltipc {
    position: relative;
    display: inline-block;
}

.tooltipc .tooltiptextc {
    font-weight: 400;
    line-height: 1.5;
    visibility: hidden;
    width: 200px;
    background-color: #000;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -100px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: .875rem;
}

.tooltipc .tooltiptextc::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #000 transparent;
}

.tooltipc:hover .tooltiptextc {
    visibility: visible;
    opacity: 1;
    color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/anonymouspage/anonymoususerpage.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,sBAAsB;IACtB,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,SAAS;IACT,mBAAmB;IACnB,UAAU;IACV,wBAAwB;IACxB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,SAAS;IACT,iBAAiB;IACjB,iBAAiB;IACjB,mBAAmB;IACnB,sDAAsD;AAC1D;;AAEA;IACI,mBAAmB;IACnB,UAAU;IACV,WAAW;AACf","sourcesContent":[".tooltipc {\n    position: relative;\n    display: inline-block;\n}\n\n.tooltipc .tooltiptextc {\n    font-weight: 400;\n    line-height: 1.5;\n    visibility: hidden;\n    width: 200px;\n    background-color: #000;\n    color: #fff;\n    text-align: center;\n    border-radius: 6px;\n    padding: 5px 10px;\n    position: absolute;\n    z-index: 1;\n    top: 100%;\n    left: 50%;\n    margin-left: -100px;\n    opacity: 0;\n    transition: opacity 0.3s;\n    font-size: .875rem;\n}\n\n.tooltipc .tooltiptextc::after {\n    content: \"\";\n    position: absolute;\n    bottom: 100%;\n    left: 50%;\n    margin-left: -5px;\n    border-width: 5px;\n    border-style: solid;\n    border-color: transparent transparent #000 transparent;\n}\n\n.tooltipc:hover .tooltiptextc {\n    visibility: visible;\n    opacity: 1;\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
