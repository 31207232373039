import { Component, OnInit } from '@angular/core';
import { Title } from '@node_modules/@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@node_modules/@angular/router';
import { filter, map } from '@node_modules/rxjs/operators';

@Component({
  selector: 'app-webfacing',
  templateUrl: './webfacing.component.html',
  styleUrls: ['./webfacing.component.css'],
})
export class WebfacingComponent implements OnInit {

  constructor(private router: Router,
    private titleService: Title,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    // Handle initial load
    this.setTitle(this.route);

    // Handle title updates on navigation
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.route),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        map(route => route.snapshot.data['title'])
      )
      .subscribe(title => {
        if (title) {
          this.titleService.setTitle(`${title}`);
        }
      });
  }

  private setTitle(route: ActivatedRoute): void {
    let currentRoute = route;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }
    const title = currentRoute.snapshot.data['title'];
    if (title) {
      this.titleService.setTitle(`${title}`);
    }
  }

}
