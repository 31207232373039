
import { AbpModule } from 'abp-ng2-module';
import * as ngCommon from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuickSideBarChat } from '@app/shared/layout/chat/QuickSideBarChat';
import { ChatSignalrService } from '@app/shared/layout/chat/chat-signalr.service';
import { LinkAccountModalComponent } from '@app/shared/layout/link-account-modal.component';
import { LinkedAccountsModalComponent } from '@app/shared/layout/linked-accounts-modal.component';
import { LoginAttemptsModalComponent } from '@app/shared/layout/login-attempts-modal.component';
import { ChangeProfilePictureModalComponent } from '@app/shared/layout/profile/change-profile-picture-modal.component';
import { ServiceProxyModule } from '@shared/service-proxies/service-proxy.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { FileUploadModule } from 'ng2-file-upload';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressBarModule } from 'primeng/progressbar';
import { FileUploadModule as PrimeNgFileUploadModule } from 'primeng/fileupload';
import { TableModule } from 'primeng/table';
import { ImpersonationService } from './admin/users/impersonation.service';
import { FileUploadSendEmailService } from '@app/shared/common/fileupload/file-upload.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppCommonModule } from './shared/common/app-common.module';
import { ChatBarComponent } from './shared/layout/chat/chat-bar.component';
import { ChatFriendListItemComponent } from './shared/layout/chat/chat-friend-list-item.component';
import { ChatMessageComponent } from './shared/layout/chat/chat-message.component';
import { FooterComponent } from './shared/layout/footer.component';
import { HeaderComponent } from './shared/layout/header.component';
import { LinkedAccountService } from './shared/layout/linked-account.service';
import { SideBarMenuComponent } from './shared/layout/nav/side-bar-menu.component';
import { TopBarMenuComponent } from './shared/layout/nav/top-bar-menu.component';
import { UserNotificationHelper } from './shared/layout/notifications/UserNotificationHelper';
import { HeaderNotificationsComponent } from './shared/layout/notifications/header-notifications.component';
import { NotificationSettingsModalComponent } from './shared/layout/notifications/notification-settings-modal.component';
import { NotificationsComponent } from './shared/layout/notifications/notifications.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
// import { TreeviewModule } from 'ngx-treeview';
import { HttpCancelService } from 'services/project.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { OrderModule } from 'ngx-order-pipe';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxCaptureModule } from 'ngx-capture';
import { GoogleTagManagerService } from './main/cablepro/google-tag-manager.service';
import { GTMGuard } from './main/cablepro/gtm-gurad';
import { PopoverModule } from '@easiangular/ngx-smart-popover';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SocialLoginModule, SocialAuthServiceConfig, FacebookLoginProvider } from 'angularx-social-login';
import {
    GoogleLoginProvider
} from 'angularx-social-login';
import { environment } from 'environments/environment';
import { LoaderComponent } from './main/cablepro/loader.component';
import { LoaderService } from './main/cablepro/loader.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from './shared/common/confirmation-modal/confirmation-modal.component';
import { NavigationService } from './main/cablepro/navigation.service';
import { MaintenanceComponent } from 'maintenance/maintenance.component';
import { providePrimeNG } from 'primeng/config';
import Aura from '@primeng/themes/aura';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        HeaderNotificationsComponent,
        SideBarMenuComponent,
        TopBarMenuComponent,
        FooterComponent,
        LoginAttemptsModalComponent,
        LinkedAccountsModalComponent,
        LinkAccountModalComponent,
        // ChangePasswordModalComponent,
        ChangeProfilePictureModalComponent,
        // MySettingsModalComponent,
        // SmsVerificationModalComponent,
        NotificationsComponent,
        ChatBarComponent,
        ChatFriendListItemComponent,
        NotificationSettingsModalComponent,
        ChatMessageComponent,
        ConfirmationModalComponent,
        MaintenanceComponent
    ],
    imports: [
        ngCommon.CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        ModalModule,
        TooltipModule,
        TabsModule,
        FileUploadModule,
        AbpModule,
        AppRoutingModule,
        UtilsModule,
        AppCommonModule.forRoot(),
        ServiceProxyModule,
        TableModule,
        PaginatorModule,
        NgxPaginationModule,
        PrimeNgFileUploadModule,
        ProgressBarModule, BrowserAnimationsModule, NgxSliderModule,
        OrderModule,
        NgxCaptureModule,
        PopoverModule,
        NgSelectModule,
        NgxSkeletonLoaderModule.forRoot(),
        SocialLoginModule,
        LoaderComponent
    ],
    providers: [
        ImpersonationService,
        LinkedAccountService,
        UserNotificationHelper,
        ChatSignalrService,
        QuickSideBarChat,
        NgbActiveModal,
        FileUploadSendEmailService,
        HttpCancelService,
        GoogleTagManagerService,
        LoaderService,
        NavigationService,
        GTMGuard,
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            environment.GoogleClientId,
                            {
                                scope: 'profile email',
                                plugin_name: 'CablePro', //can be any name
                                prompt: 'select_account'
                            }
                        )
                    },
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider(
                            environment.FacebookClientId,
                            {
                                scope: 'email',
                                return_scopes: true,
                                auth_type: 'reauthenticate',
                                enable_profile_selector: true
                            }
                        )
                    }
                ],
                onError: (err) => {
                    console.error(err);
                }
            } as SocialAuthServiceConfig,
        },
        providePrimeNG({
            theme: {
                preset: Aura,
                options: {
                    darkModeSelector: false || 'none'
                }
            }
        })
    ]
})
export class AppModule { }